//Create react context for chat
import React from 'react';

import { User } from '../../models/user';

interface IChatContext {
  searchUserText: string;
  handleSearchUser: (text: string) => void;
  selectedUser: User | undefined;
  handleSelectUser: (user: User) => void;
  selectedChatId: string;
  handleSelectChatId: (chatId: string) => void;
  markMessageAsRead: (messageId: string | number, chatId: string) => void;
  sendMessage: (
    message: string,
    to: number | undefined,
    chatId: string
  ) => void;
}

export const chatContextInitialValue = {
  searchUserText: '',
  handleSearchUser: () => {},
  selectedUser: undefined,
  handleSelectUser: () => {},
  selectedChatId: '',
  handleSelectChatId: () => {},
  markMessageAsRead: () => {},
  sendMessage: () => {},
};
export const ChatContext = React.createContext<IChatContext>(
  chatContextInitialValue
);
export const ChatProvider = ChatContext.Provider;
export const ChatConsumer = ChatContext.Consumer;
