import React, { useEffect, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';

import { axiosInstance } from '../store/apiUtils';

interface FileViewerProps {
  fileName: string;
}

const FileViewer: React.FC<FileViewerProps> = ({ fileName }) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const fetchImage = async (fileName: string) => {
    if (fileName) {
      setError(false);
      setImageUrl('');
      try {
        const response = await axiosInstance.get(`/file-names/${fileName}`, {
          responseType: 'blob',
        });

        const imageBlob = response.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageUrl(imageObjectURL);
      } catch (error) {
        console.error('Error fetching image:', error);
        setError(true);
      }
    }
  };

  useEffect(() => {
    fetchImage(fileName);
  }, [fileName]);

  return (
    <div>
      {error ? (
        <div>
          <p>Error loading file</p>
          <IconButton onClick={() => fetchImage(fileName)}>
            <RefreshIcon />
          </IconButton>
        </div>
      ) : imageUrl ? (
        imageUrl.includes('.pdf') ? (
          <iframe
            title="PDF preview"
            src={imageUrl}
            className="w-full"
            style={{ height: '500px' }}
          />
        ) : (
          <img src={imageUrl} alt={fileName} />
        )
      ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
};

export default FileViewer;
