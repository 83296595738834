import * as React from 'react';
import { useSelector } from 'react-redux';

import { Person } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import SearchInput from '../../components/fields/searchInput';
import useChatName from '../../hooks/useChatName';
import useChatNames from '../../hooks/useChatNames';
import { User } from '../../models/user';
import {
  chatMessagesForChatId,
  unreadMessagesSelector,
} from '../../store/selectors/chat.selectors';
import { usersSelector } from '../../store/selectors/users.selectors';
import { ChatUser } from '../../store/slices/chat.slice';
import { Colors } from '../../utils/colors';
import { getFullName } from '../../utils/utils';
import { ChatContext } from './chatContext';
import ChatUserSearch from './chatUserSearch';

interface IChatUsersProps {
  chatUsers: ChatUser[];
}

const ChatUsers: React.FunctionComponent<IChatUsersProps> = ({ chatUsers }) => {
  const users = useSelector(usersSelector);
  const { searchUserText, selectedChatId, handleSearchUser } =
    React.useContext(ChatContext);
  console.log({ chatUsers, selectedChatId });
  const chatNames = useChatNames(chatUsers.map((chatUser) => chatUser.userId));
  const chatUsersWithNames = chatUsers.map((chatUser) => ({
    ...chatUser,
    name: chatNames[chatUser.userId],
  }));
  return (
    <div className="w-1/4">
      <div className="flex flex-row items-center justify-between p-2">
        <p className="text-xl font-bold">Chats</p>
        <div>
          <ChatUserSearch />
        </div>
      </div>
      <div className="">
        <SearchInput
          placeholder="Search users..."
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            handleSearchUser(evt.target.value);
          }}
          value={searchUserText}
        />
      </div>
      <div className="h-full overflow-auto ">
        <List
          sx={{
            overflow: 'auto',
            height: 'calc(100vh - 250px)',
          }}
        >
          {chatUsersWithNames
            ?.filter((chatUser: ChatUser) =>
              searchUserText
                ? (chatUser.name || '')
                    ?.toLowerCase()
                    ?.includes(searchUserText.toLowerCase())
                : true
            )
            .map((chatUser) => (
              <ChatUserListItem chatUser={chatUser} users={users} />
            ))}
        </List>
      </div>
    </div>
  );
};

const ChatUserListItem = ({
  chatUser,
  users,
}: {
  chatUser: ChatUser;
  users: User[];
}) => {
  const { selectedChatId, handleSelectUser, handleSelectChatId } =
    React.useContext(ChatContext);
  const chatMessages = useSelector(chatMessagesForChatId(chatUser.id));
  const unreadMessages = useSelector(
    unreadMessagesSelector(chatUser.id, chatUser.userId)
  );
  return (
    <ListItem
      className="hover:bg-gray-300 border-b-2 border-gray-200"
      sx={{
        cursor: 'pointer',
        backgroundColor:
          selectedChatId === chatUser.id ? 'lightgrey' : 'inherit',
      }}
      key={chatUser.id}
      onClick={() => {
        handleSelectUser(
          users.find((user) => user.id === chatUser.userId) ?? ({} as User)
        );
        handleSelectChatId(chatUser.id);
      }}
    >
      <ListItemAvatar>
        <ChatUserInfo label={chatUser.name || ''} />
      </ListItemAvatar>
      <ListItemText primary={chatUser.name} />
      {unreadMessages?.length > 0 ? (
        <Badge
          className=" text-white w-8 h-8 text-xs font-bold items-center justify-center"
          style={{
            background: Colors.primary,
            borderRadius: '50%',
          }}
        >
          {unreadMessages.length > 10 ? '10+' : unreadMessages.length}
        </Badge>
      ) : (chatMessages?.length ?? 0) === 0 && chatUser.unreadMessageCount ? (
        <Badge
          className=" text-white w-8 h-8 text-xs font-bold items-center justify-center"
          style={{
            background: Colors.primary,
            borderRadius: '50%',
          }}
        >
          {chatUser.unreadMessageCount}
        </Badge>
      ) : null}
    </ListItem>
  );
};

interface IChatUserInfoProps {
  label: string;
}
export const ChatUserInfo: React.FC<IChatUserInfoProps> = ({ label }) => {
  return (
    // <StyledBadge
    //   overlap="circular"
    //   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    //   variant="dot"
    // >
    <Avatar>
      <Person />
    </Avatar>
    // </StyledBadge>
  );
};
export default ChatUsers;
