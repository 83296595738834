import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '../components/form/primaryButton';

interface IUnauthorizedScreenProps {}

const UnauthorizedScreen: React.FunctionComponent<IUnauthorizedScreenProps> = (
  props
) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => navigate('/'), 3000);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center mt-24">
      <div className="text-center">
        <div className="text-6xl text-red-600 font-bold mb-4">Oops!</div>
        <p className="text-2xl mb-2">
          Something is not right, may be you are on a wrong page.
        </p>
        <p className="text-xl mb-2">
          You are being redirected to home page in 3 seconds...
        </p>
        <p className="text-xl mb-8">
          If the redirection doesn't work please click on the button below that
          will navigate you to home page.
        </p>
        <div className="mb-6">
          <img
            src={`${process.env.PUBLIC_URL}/images/unauthorized.png`} // Path to your unauthorized illustration
            alt="Unauthorized access"
            className="mx-auto h-64"
          />
        </div>
        <PrimaryButton onClick={() => navigate('/')} label="Go to Homepage" />
      </div>
    </div>
  );
};

export default UnauthorizedScreen;
