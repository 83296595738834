import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import useChat from '../hooks/useChat';
import { User } from '../models/user';
import { authUserProfileSelector } from '../store/selectors/auth.selectors';
import ChairDashboard from './dashboards/chairDashboard';
import DelegateDashboard from './dashboards/delegateDashboard';
import ModeratorDashboard from './dashboards/moderatorDashboard';
import ObserverDashboard from './dashboards/observerDashboard';
import SuperAdminDashboard from './dashboards/superAdminDashboard';

const Dashboards: Record<number, React.ReactNode> = {
  1: <SuperAdminDashboard />,
  2: <ModeratorDashboard />,
  3: <ChairDashboard />,
  4: <ChairDashboard />,
  5: <DelegateDashboard />,
  6: <ObserverDashboard />,
};

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
  const userProfile: User | undefined = useSelector(authUserProfileSelector);
  useChat();
  if (!userProfile?.roleId) {
    return (
      <div className="flex flex-row items-center justify-center mt-12">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>{Dashboards[userProfile.roleId] ?? <div>No dashboard found</div>}</div>
  );
};

export default Dashboard;
