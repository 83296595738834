import React, { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import FileViewerById from './fileViewerById';
import { PrimaryButton } from './form/primaryButton';

interface FileViewerIconProps {
  id: number;
  label: string;
}

const FileViewerIcon: React.FC<FileViewerIconProps> = ({ id, label }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="flex flex-row justify-between items-center">
      <span>{label}</span>
      <IconButton onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>File Viewer</DialogTitle>
        <DialogContent>
          <FileViewerById id={id} />
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            onClick={handleClose}
            label="Close"
            type="button"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileViewerIcon;
