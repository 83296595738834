import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Resolution } from '../../models/resolution';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

export type ResolutionState = {
  resolutionsByCommitteeId: Record<string, Resolution[]>;
  isLoading: boolean;
  error: string;
};

const initialState: ResolutionState = {
  resolutionsByCommitteeId: {},
  isLoading: false,
  error: '',
};

const getResolutionsByCommitteeId = createAsyncThunk(
  'resolutions/list',
  async (committeeId: number, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<Resolution[]>>(
        `/committees/${committeeId}/resolutions?limit=0&offset=0`
      );
      console.log(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const createResolution = createAsyncThunk(
  'resolutions/create',
  async (
    {
      resolution,
      committeeId,
      onSuccess,
    }: { resolution: Resolution; committeeId: number; onSuccess?: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<Resolution>, Resolution>(
        `/committees/${committeeId}/resolutions`,
        resolution
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Resolution created successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateResolution = createAsyncThunk(
  'resolutions/update',
  async (
    {
      committeeId,
      partialResolution = {},
      onSuccess,
    }: {
      committeeId: number;
      partialResolution: Partial<Resolution>;
      onSuccess?: () => void;
    },
    thunkApi
  ) => {
    try {
      const response = await API.patch<
        ApiResponse<Partial<Resolution>>,
        Partial<Resolution>
      >(
        `/committees/${committeeId}/resolutions/${partialResolution?.id}`,
        partialResolution
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Resolution updated successfully'));
      return { ...partialResolution };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const resolutionsSlice = createSlice({
  name: 'resolutions',
  initialState,
  reducers: {
    resetResolutionsError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResolutionsByCommitteeId.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(getResolutionsByCommitteeId.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.isLoading = false;
        state.resolutionsByCommitteeId[action.meta.arg] = payload;
        state.error = '';
      })
      .addCase(getResolutionsByCommitteeId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      .addCase(createResolution.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createResolution.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.isLoading = false;
        state.resolutionsByCommitteeId[action.meta.arg.committeeId] =
          state.resolutionsByCommitteeId[action.meta.arg.committeeId] || [];
        state.resolutionsByCommitteeId[action.meta.arg.committeeId].push(
          payload
        );
      })
      .addCase(createResolution.rejected, (state, action) => {
        state.isLoading = false;
        state.error = (action.payload as string) || '';
      })
      .addCase(updateResolution.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateResolution.fulfilled, (state, action) => {
        state.isLoading = false;
        const resolution = action.payload;
        const committeeId = action.meta.arg.committeeId;
        const index = state.resolutionsByCommitteeId[committeeId].findIndex(
          (r) => r.id === resolution.id
        );
        state.resolutionsByCommitteeId[committeeId][index] = {
          ...state.resolutionsByCommitteeId[committeeId][index],
          ...resolution,
        };
      })
      .addCase(updateResolution.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      });
  },
});
const { resetResolutionsError } = resolutionsSlice.actions;
export {
  getResolutionsByCommitteeId,
  createResolution,
  updateResolution,
  resetResolutionsError,
};
export const resolutionsReducer = resolutionsSlice.reducer;
