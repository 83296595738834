import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Accordion, AccordionDetails, Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    styled, Typography
} from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import FileInput from '../components/fields/fileInput';
import FileViewerById from '../components/fileViewerById';
import FileViewerIcon from '../components/fileViewerIcon';
import { PrimaryButton } from '../components/form/primaryButton';
import Table from '../components/table';
import { getStatusColumn } from '../components/table/statusColumn';
import { Event } from '../models/event';
import { AppDispatch } from '../store';
import {
    eventsErrorSelector, eventsIsLoadingSelector, eventsSelector
} from '../store/selectors/events.selectors';
import { createEvent, getEvents, resetEventError, updateEvent } from '../store/slices/events.slice';
import { resetErrorOnFocus } from '../utils/utils';
import { validateName, Validations } from '../utils/validations';

interface IEventsProps {}

const Events: React.FunctionComponent<IEventsProps> = (props) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, any | undefined>
  >({});
  const [editPayload, setEditPayload] = useState<any>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const [editConfirmation, setEditConfirmation] = useState<boolean>(false);
  const events: Event[] = useSelector(eventsSelector);
  const isLoading: boolean = useSelector(eventsIsLoadingSelector);
  const error: string = useSelector(eventsErrorSelector);
  const [logo, setLogo] = useState<File | undefined>(undefined);
  const [map, setMap] = useState<File | undefined>(undefined);
  const [schedule, setSchedule] = useState<File | undefined>(undefined);
  useEffect(() => {
    dispatch(getEvents({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = React.useMemo<MRT_ColumnDef<Event>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 60,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: ({ row, table }) => {
          return <div className="pt-2"></div>;
        },
      },
      {
        accessorKey: 'name',
        header: 'Event Name',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'name',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'userCount',
        header: 'No of Users',
        size: 150,
        enableColumnFilter: false,
        Cell: ({ row }) => <div>{row.original.userCount || 0}</div>,
        Edit: ({ row, table }) => {
          return null;
        },
      },
      {
        accessorKey: 'message',
        header: 'Message',
        muiEditTextFieldProps: {
          multiline: true,
          rows: 4,
          error: !!validationErrors?.message,
          helperText: validationErrors?.message,
        },
        hidden: true,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        muiEditTextFieldProps: {
          multiline: true,
          rows: 4,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
        },
        hidden: true,
      },
      {
        accessorKey: 'logoView',
        header: 'Logo',
        hidden: true,
        Edit: ({ row, table }) => {
          if (row.original.logo)
            return (
              <FileViewerIcon
                id={row.original.logo}
                label="Preview existing logo"
              />
            );
        },
        Cell: ({ row }) => {
          return null;
        },
      },
      {
        accessorKey: 'logo',
        header: 'Logo',
        hidden: true,
        Edit: ({ row, table }) => {
          return (
            <div className="flex flex-col gap-4">
              <FileInput
                required={true}
                onChange={(e: any) => {
                  setLogo(e.target.files?.[0] ?? null);
                }}
                errorText={validationErrors?.logo}
                label="Logo"
                onFocus={() =>
                  resetErrorOnFocus({
                    key: 'logo',
                    validationErrors,
                    setValidationErrors,
                  })
                }
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'mapView',
        header: 'Map',
        hidden: true,
        Edit: ({ row, table }) => {
          if (row.original.map)
            return (
              <FileViewerIcon
                id={row.original.map}
                label="Preview existing map"
              />
            );
        },
        Cell: ({ row }) => {
          return null;
        },
      },
      {
        accessorKey: 'map',
        header: 'Map',
        hidden: true,
        muiEditTextFieldProps: {
          type: 'file',
          focused: true,
        },
        Edit: ({ row, table }) => {
          return (
            <div className="flex flex-col gap-4">
              <FileInput
                onChange={(e: any) => {
                  setMap(e.target.files?.[0] ?? null);
                }}
                errorText={validationErrors?.map}
                label="Map"
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'scheduleView',
        header: 'Schedule',
        hidden: true,
        Edit: ({ row, table }) => {
          if (row.original.schedule)
            return (
              <FileViewerIcon
                id={row.original.schedule}
                label="Preview existing schedule"
              />
            );
        },
        Cell: ({ row }) => {
          return null;
        },
      },
      {
        accessorKey: 'schedule',
        header: 'Schedule',
        hidden: true,
        muiEditTextFieldProps: {
          type: 'file',
          focused: true,
        },
        Edit: ({ row, table }) => {
          return (
            <div className="flex flex-col gap-4">
              <FileInput
                onChange={(e: any) => {
                  setSchedule(e.target.files?.[0] ?? null);
                }}
                errorText={validationErrors?.schedule}
                label="Schedule"
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'commencementDate',
        header: 'Commencement Date',
        size: 120,
        filterVariant: 'date',
        muiFilterDatePickerProps(props) {
          return {
            ...props,
            inputVariant: 'outlined',
            format: 'YYYY/MM/DD',
          };
        },
        muiEditTextFieldProps: {
          type: 'date',
          required: true,
          error: !!validationErrors?.commencementDate,
          helperText: validationErrors?.commencementDate,
          InputLabelProps: {
            shrink: true,
          },
          inputProps: {
            min: moment().format('YYYY-MM-DD'),
            format: 'YYYY-MM-DD',
          },

          format: 'YYYY-MM-DD',
          onFocus: () =>
            resetErrorOnFocus({
              key: 'commencementDate',
              validationErrors,
              setValidationErrors,
            }),
        },
        filterFn: (row, id, filterValue) => {
          return (
            row.original.commencementDate === filterValue.format('YYYY-MM-DD')
          );
        },
      },
      getStatusColumn<Event>(validationErrors, 'publicAccess', 'Public Access'),
      getStatusColumn<Event>(validationErrors),
    ],
    [validationErrors]
  );
  const handleCloseEditConfirmation = () => {
    setEditConfirmation(false);
  };
  const handleOpenEditConfirmation = () => {
    setEditConfirmation(true);
  };
  return (
    <div className="m-4">
      <Dialog
        open={editConfirmation}
        onClose={handleCloseEditConfirmation}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className="flex flex-row justify-around">
          Confirmation?
        </DialogTitle>
        <DialogContent>
          <Alert severity="error">
            Are you sure you want to remove this event from public access? If
            there are any active users using this event, they should log out and
            relogin.
          </Alert>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            onClick={handleCloseEditConfirmation}
            variant="outlined"
            color="primary"
            label="Close"
          />
          <PrimaryButton
            onClick={() => {
              handleCloseEditConfirmation();
              editPayload.action();
              setEditPayload(undefined);
            }}
            variant="contained"
            label="Confirm"
          />
        </DialogActions>
      </Dialog>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={events}
        title="Events"
        enableEditing={true}
        additionalEditComponents={(row) =>
          error && <Alert severity="error">{error}</Alert>
        }
        columnVisibility={{
          id: false,
          logo: false,
          map: false,
          schedule: false,
          message: false,
          description: false,
          logoView: false,
          mapView: false,
          scheduleView: false,
        }}
        resetOnFormClose={() => {
          setValidationErrors({});
          dispatch(resetEventError());
        }}
        onCreateRowSave={async ({ values, table }) => {
          values = { ...values, status: !!values.status };

          const newValidationErrors = validateEvent(values, logo);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          delete values.id;
          values.commencementDate = moment(values.commencementDate).format(
            'YYYY-MM-DD'
          );
          await dispatch(
            createEvent({
              event: values,
              logo,
              map,
              schedule,
              onSuccess: () => table.setCreatingRow(null),
            })
          );
          //exit creating mode
        }}
        onEditRowSave={async ({ row, values, table }) => {
          const changedValues: any = {};
          Object.keys(values).forEach((key) => {
            if (values[key] !== row.original[key]) {
              changedValues[key] = values[key];
            }
          });
          const newValidationErrors = validateEvent(values, logo, true);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          values.commencementDate = moment(values.commencementDate).format(
            'YYYY-MM-DD'
          );
          console.log(row, values);
          if (
            row.original.publicAccess === true &&
            values.publicAccess === false
          ) {
            handleOpenEditConfirmation();
            setEditPayload({
              action: () => {
                dispatch(
                  updateEvent({
                    event: values,
                    logo,
                    map,
                    schedule,
                    onSuccess: () => table.setEditingRow(null),
                  })
                );
              },
            });
          } else {
            dispatch(
              updateEvent({
                event: values,
                logo,
                map,
                schedule,
                onSuccess: () => table.setEditingRow(null),
              })
            );
          }
          //exit editing mode
        }}
        renderDetailPanel={ExpandedRow}
      />
    </div>
  );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const ExpandedRow = ({ row }: { row: MRT_Row<Event> }) => {
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const keys = ['Logo', 'Map', 'Schedule'];
  return (
    //lets return an material accordion with three items logo, map and schedule and render FileViewerById on expand
    <div>
      {keys.map((key) => {
        return (
          <Accordion expanded={expanded === key} onChange={handleChange(key)}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{key}</Typography>
            </AccordionSummary>
            <AccordionDetails className="text-grey-300">
              {expanded === key && (
                <FileViewerById id={row.original?.[key.toLowerCase()]} />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

function validateEvent(event: Event, logoFile?: File, isEditMode?: boolean) {
  return {
    name: validateName({ value: event.name, label: 'Event Name' }),
    message:
      event.message &&
      !Validations.validateMaxLength({
        value: event.message,
        maxLength: 300,
      })
        ? 'Message should be less than 300 characters'
        : '',
    description:
      event.description &&
      !Validations.validateMaxLength({
        value: event.description,
        maxLength: 300,
      })
        ? 'Description should be less than 300 characters'
        : '',
    ...(isEditMode ? {} : { logo: !logoFile ? 'Logo is Required' : '' }),
    // map: !mapFile ? 'Map is Required' : '',
    // schedule: !scheduleFile ? 'Schedule is Required' : '',
    commencementDate: !Validations.isValidDate(event.commencementDate)
      ? 'Date is required'
      : '',
  };
}

export default Events;
