import * as React from 'react';

import { TextField } from '@mui/material';

interface IFileInputProps {
  onChange: (e: any) => void;
  errorText?: string;
  label: string;
  required?: boolean;
  accept?: string;
  onFocus?: () => void;
}

const FileInput: React.FunctionComponent<IFileInputProps> = ({
  onChange,
  errorText,
  label,
  required = false,
  accept = 'image/*,application/pdf',
  onFocus = () => {},
}) => {
  return (
    <TextField
      label={label}
      type="file"
      variant="outlined"
      fullWidth
      required={required}
      onChange={onChange}
      error={!!errorText}
      helperText={errorText}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        accept,
      }}
      onFocus={onFocus}
    />
  );
};

export default FileInput;
