import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Box, Divider, Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { routePath } from '../appRoutes';
import { Colors } from '../utils/colors';

const Settings = () => {
  const options = [
    {
      label: 'My Profile',
      path: routePath.myProfile,
    },
    {
      label: 'Update Password',
      path: routePath.updatePassword,
    },
    {
      label: 'Settings',
      path: routePath.settings,
    },
  ];
  const [selectedPath, setSelectedPath] = useState<string>(options[0].label);

  const navigate = useNavigate();
  useEffect(() => {
    navigate(`${routePath.myAccount}${options[0].path}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Paper className="m-4 flex h-screen">
      <div className="w-1/4  p-4 overflow-auto">
        <List>
          {options.map((option) => (
            <ListItem
              className="hover:bg-gray-100"
              sx={{
                cursor: 'pointer',
                color: selectedPath === option.label ? Colors.primary : 'grey',
                fontWeight: selectedPath === option.label ? 'bold' : 'normal',
              }}
              key={option.label}
              onClick={() => {
                setSelectedPath(option.label);
                navigate(`${routePath.myAccount}${option.path}`);
              }}
            >
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="w-3/4 p-4 overflow-auto">
        <Box sx={{ mb: 2, fontSize: '1.25rem', fontWeight: 'bold' }}>
          {selectedPath}
        </Box>
        <Divider />
        <div className="mt-2">
          <Outlet />
        </div>
      </div>
    </Paper>
  );
};

export default Settings;
