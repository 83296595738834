import { RootState } from '../';

export const amendmentsSelector = (state: RootState) => state.amendments;
export const amendmentsByResolutionIdSelector =
  (resolutionId: number) => (state: RootState) =>
    state.amendments.amendmentsByResolutionId[resolutionId];

export const isLoadingAmendmentsSelector = (state: RootState) =>
  state.amendments.isLoading;
export const amendmentsErrorSelector = (state: RootState) =>
  state.amendments.error;
