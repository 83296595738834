import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Country } from '../../models/country';
import API, { ApiResponse } from '../apiUtils';

var iso3311a2 = require('iso-3166-1-alpha-2');
interface Role {
  id: number;
  name: string;
  deletedAt: null;
  createdAt: string;
  updatedAt: string;
}

export type App = {
  roles: Role[];
  countries: Country[];
  countryToISOCode2Map: Record<string, string>;
  filesById: Record<string, any>;
  isLoading: boolean;
  error: string;
  toastMessage: string;
};

const initialState: App = {
  roles: [],
  countries: [],
  countryToISOCode2Map: {},
  filesById: {},
  isLoading: false,
  error: '',
  toastMessage: '',
};

const getRoles = createAsyncThunk(
  'app/roles',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<Role[]>>('/roles');
      console.log(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getCountries = createAsyncThunk(
  'app/countries',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<Country[]>>(
        '/countries?limit=0&offset=0'
      );
      console.log(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getFileById = createAsyncThunk(
  'app/file',
  async (id: number, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<any>>(`/files/${id}`);
      console.log(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToastMessage: (state, action) => {
      state.toastMessage = action.payload;
    },
    clearToastMessage: (state, action) => {
      state.toastMessage = '';
    },
  },
  extraReducers(builder) {
    //Roles
    builder.addCase(getRoles.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload;
      state.isLoading = false;
      state.roles = payload;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    //Countries
    builder.addCase(getCountries.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
      state.countryToISOCode2Map = invertKeyValue(iso3311a2.getData());
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload;
      state.isLoading = false;
      state.countries = payload;
    });
    builder.addCase(getCountries.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });

    //get files by id
    builder.addCase(getFileById.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getFileById.fulfilled, (state, action) => {
      if (!state.filesById) state.filesById = {};
      const payload: any = action.payload;
      state.isLoading = false;
      state.filesById[payload.id] = payload;
    });
    builder.addCase(getFileById.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
  },
});

function invertKeyValue<T extends string | number>(
  obj: Record<T, T>
): Record<T, T> {
  const invertedObj: Record<T, T> = {} as Record<T, T>;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      invertedObj[value] = key;
    }
  }
  return invertedObj;
}

const { setToastMessage, clearToastMessage } = appSlice.actions;

export {
  getRoles,
  getCountries,
  getFileById,
  setToastMessage,
  clearToastMessage,
  initialState,
};

export const appReducer = appSlice.reducer;
