import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Add } from '@mui/icons-material';
import { Button, ClickAwayListener, IconButton, Radio } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import RadioGroup from '@mui/material/RadioGroup';

import { roles } from '../../appRoutes';
import SearchInput from '../../components/fields/searchInput';
import useChatName from '../../hooks/useChatName';
import useChatNames from '../../hooks/useChatNames';
import { User } from '../../models/user';
import { AppDispatch } from '../../store';
import { authUserRoleSelector } from '../../store/selectors/auth.selectors';
import { usersSelector } from '../../store/selectors/users.selectors';
import { addNewChat } from '../../store/slices/chat.slice';
import { getFullName } from '../../utils/utils';
import { ChatContext } from './chatContext';

interface UserSearchPopperProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  users: User[];
  chatNames: Record<string, string>;
  onSelectUser: (user: User) => void;
  onClose: () => void;
}

const ChatUserSearchPopper: React.FC<UserSearchPopperProps> = ({
  open,
  anchorEl,
  users,
  chatNames,
  onSelectUser,
  onClose,
}) => {
  const userRole = useSelector(authUserRoleSelector);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const handleSelectUser = (user: User) => {
    onSelectUser(user);
  };
  const filtersOptionsByRole = {
    [roles.OBSERVER]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.OBSERVER]: 'Observer',
    },
    [roles.DELEGATE]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.DELEGATE]: 'Delegate',
      [roles.CHAIR]: 'Chair',
      [roles.DEPUTY_CHAIR]: 'Deputy Chair',
    },
    [roles.DEPUTY_CHAIR]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.DELEGATE]: 'Delegate',
      [roles.DEPUTY_CHAIR]: 'Deputy Chair',
    },
    [roles.DEPUTY_CHAIR]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.DELEGATE]: 'Delegate',
      [roles.DEPUTY_CHAIR]: 'Deputy Chair',
      [roles.CHAIR]: 'Chair',
    },
    [roles.CHAIR]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.DELEGATE]: 'Delegate',
      [roles.DEPUTY_CHAIR]: 'Deputy Chair',
      [roles.CHAIR]: 'Chair',
    },
    [roles.SUPER_ADMIN]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.DELEGATE]: 'Delegate',
      [roles.DEPUTY_CHAIR]: 'Deputy Chair',
      [roles.CHAIR]: 'Chair',
      [roles.OBSERVER]: 'Observer',
    },
    [roles.MODERATOR]: {
      [roles.MODERATOR]: 'Moderator',
      [roles.DELEGATE]: 'Delegate',
      [roles.DEPUTY_CHAIR]: 'Deputy Chair',
      [roles.CHAIR]: 'Chair',
      [roles.OBSERVER]: 'Observer',
    },
  };
  const rolesForFilter = filtersOptionsByRole[userRole || ''];
  console.log({ users, chatNames });
  const handleClose = () => {
    onClose();
    setSearchTerm('');
    setSelectedRole('');
  };
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      sx={{
        borderRadius: '5px',
      }}
    >
      <div
        style={{
          border: '1px solid #ccc',
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '8px',
          maxWidth: '300px',
        }}
      >
        <SearchInput
          placeholder="Search users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="p-1">
          <FormControl>
            <FormLabel
              id="radio-buttons-group-label"
              className="flex flex-row items-center justify-between"
            >
              Filter by role
              {selectedRole && (
                <Button color="error" onClick={() => setSelectedRole('')}>
                  Clear
                </Button>
              )}
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={selectedRole}
              sx={{
                flexDirection: 'row',
              }}
            >
              {Object.keys(rolesForFilter).map((role, index) => (
                <FormControlLabel
                  value={role}
                  control={<Radio />}
                  label={rolesForFilter[role]}
                  key={index}
                  onChange={() => setSelectedRole(role)}
                  checked={selectedRole === role}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <List
          dense
          style={{
            maxHeight: '200px',
            overflow: 'scroll',
          }}
        >
          {users
            .filter((user) => user.status)
            .filter((user) =>
              selectedRole
                ? user.roleId === Number(selectedRole)
                : `${userRole}` === roles.SUPER_ADMIN
                ? true
                : `${user.roleId}` !== `${roles.SUPER_ADMIN}`
            )
            .filter((user) =>
              (chatNames[user.id]?.toLowerCase() ?? '').includes(
                searchTerm.toLowerCase()
              )
            )
            .map((user) => (
              <UserListItem user={user} onSelectUser={handleSelectUser} />
            ))}
        </List>
        <div className="flex flex-row justify-end">
          <Button onClick={handleClose} color="error">
            Close
          </Button>
        </div>
      </div>
    </Popper>
  );
};

const UserListItem = ({
  user,
  onSelectUser,
}: {
  user: User;
  onSelectUser: (user: User) => void;
}) => {
  const userName = useChatName(user.id);
  return (
    <ListItem button key={user.id} onClick={() => onSelectUser(user)}>
      <ListItemText primary={userName} />
    </ListItem>
  );
};

const ChatUserSearch: React.FC = () => {
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const users = useSelector(usersSelector);
  const chatNames = useChatNames(users.map((user: User) => user.id));
  const dispatch = useDispatch<AppDispatch>();
  const { handleSelectChatId, handleSelectUser } =
    React.useContext(ChatContext);
  const handleAddUserClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopperOpen((prevOpen) => !prevOpen);
  };

  const onSelectChat = (user: User) => {
    setPopperOpen(false);
    dispatch(
      addNewChat({
        id: uuidv4(),
        userId: user.id,
        onFailure: (chatId: string) => {
          handleSelectChatId(chatId);
          handleSelectUser(users.find((u) => u.id === user.id) ?? ({} as User));
        },
      })
    );
  };

  return (
    <div>
      <IconButton onClick={handleAddUserClick}>
        <Add />
      </IconButton>
      <ChatUserSearchPopper
        open={popperOpen}
        anchorEl={anchorEl}
        users={users}
        onSelectUser={onSelectChat}
        chatNames={chatNames}
        onClose={() => setPopperOpen(false)}
      />
    </div>
  );
};

export default ChatUserSearch;
