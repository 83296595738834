import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../store';
import { filesByIdSelector } from '../store/selectors/app.selectors';
import { getFileById } from '../store/slices/app.slice';

//create a hook useFile with id as prop
const useFile = ({ id }: { id: number }) => {
  const dispatch = useDispatch<AppDispatch>();
  const file = useSelector(filesByIdSelector(id));
  useEffect(() => {
    if (id && !file) {
      dispatch(getFileById(id));
    }
  }, [id]);
  return file;
};

export default useFile;
