import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Event } from '../models/event';
import { activeEventsSelector } from '../store/selectors/events.selectors';

const useEventsOptions = () => {
  const events = useSelector(activeEventsSelector);
  const eventOptions = useMemo(() => {
    return events.map((event: Event) => {
      return {
        label: event.name,
        value: event.id,
      };
    });
  }, [events]);
  return eventOptions;
};

export default useEventsOptions;
