import * as React from 'react';

import DelegateDashboard from './delegateDashboard';

interface IChairDashboardProps {}

const ChairDashboard: React.FunctionComponent<IChairDashboardProps> = (
  props
) => {
  return <DelegateDashboard />;
};

export default ChairDashboard;
