import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ForgotPassword, UpdatePassword, User } from '../../models/user';
import { LoginApiResponse, UserLoginBody } from '../api/auth';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';
import { resetChat } from './chat.slice';
import { resetUsers } from './users.slice';

export type Auth = {
  accessToken: string;
  isLoading: boolean;
  error: string;
  userId: number;
  userProfile: User | undefined;
};

const initialState: Auth = {
  accessToken: '',
  isLoading: false,
  error: '',
  userId: 0,
  userProfile: undefined,
};

const userLogin = createAsyncThunk(
  'auth/login',
  async (payload: UserLoginBody, thunkApi) => {
    try {
      const response = await API.post<
        ApiResponse<LoginApiResponse>,
        UserLoginBody
      >('/login', payload);
      thunkApi.dispatch(setToastMessage('Login success'));
      await thunkApi.dispatch(getLoggedInUserProfile({ id: response.id }));
      return response;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getLoggedInUserProfile = createAsyncThunk(
  'auth/getLoggedInUserProfile',
  async ({ id }: { id: number | string }, thunkApi) => {
    if (!id) return thunkApi.rejectWithValue('Invalid user id');
    try {
      const response = await API.get<ApiResponse<User>>(`/users/${id}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (
    {
      id,
      updatePassword,
      onSuccess,
    }: {
      id: number | string;
      updatePassword: UpdatePassword;
      onSuccess: () => void;
    },
    thunkApi
  ) => {
    try {
      const response = await API.patch<ApiResponse<User>, UpdatePassword>(
        `/users/${id}/change-password`,
        updatePassword
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Password updated successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (
    { email, onSuccess }: { email: string; onSuccess?: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<User>, ForgotPassword>(
        `/forgot-password`,
        { email }
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(
        setToastMessage('Password reset link sent to your email')
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const userLogout = createAsyncThunk(
  'auth/logout',
  async (payload, thunkApi) => {
    try {
      await API.post<ApiResponse<any>, any>(`/logout`, {});
      thunkApi.dispatch(setToastMessage('Logout success'));
      thunkApi.dispatch(logout());
      thunkApi.dispatch(resetChat());
      thunkApi.dispatch(resetUsers());
    } catch (error) {
      thunkApi.dispatch(setToastMessage('Logout success'));
      thunkApi.dispatch(logout());
      thunkApi.dispatch(resetChat());
      thunkApi.dispatch(resetUsers());
      return thunkApi.rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.accessToken = '';
      state.userId = 0;
      state.userProfile = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(userLogin.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      console.log(action);
      const payload: any = action.payload;
      state.isLoading = false;
      state.accessToken = payload.accessToken;
      state.userId = payload.id;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(getLoggedInUserProfile.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getLoggedInUserProfile.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.userProfile = payload;
    });
    builder.addCase(getLoggedInUserProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    //Update password
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });

    //forgot password
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
  },
});

const { logout } = authSlice.actions;

export {
  userLogin,
  initialState,
  getLoggedInUserProfile,
  updatePassword,
  userLogout,
  forgotPassword,
};

export const authReducer = authSlice.reducer;
