import { RootState } from '../';
import { ChatUser, messageStatus } from '../slices/chat.slice';

export const activeChatUsersSelector = (state: RootState) =>
  state.chats.chatUsers;
export const chatMessagesForChatId = (chatId: string) => (state: RootState) =>
  state.chats.messagesByChatId[chatId];

export const unreadMessagesSelector =
  (chatId: string, userId: number) => (state: RootState) => {
    const messages = state.chats.messagesByChatId[chatId] ?? [];
    return messages
      .filter((message) => {
        return message.fromUserId === userId;
      })
      .filter((message) => message.status !== messageStatus.READ);
  };

export const isTypingSelector = (chatId: string) => (state: RootState) => {
  return state.chats.typingInChat?.[chatId] ?? false;
};

export const chatTotalMessagsSelector =
  (chatId: string) => (state: RootState) => {
    return state.chats.totalMessagesByChat?.[chatId] ?? 0;
  };
