import * as React from 'react';
import { useSelector } from 'react-redux';

import SelectCommittee from '../../components/fields/selectCommittee';
import { Committee } from '../../models/committee';
import { User } from '../../models/user';
import { authUserProfileSelector } from '../../store/selectors/auth.selectors';
import { committeesListSelector } from '../../store/selectors/committees.selectors';
import Resolutions from '../resolutions';
import { NameCard } from './delegateDashboard';

interface IModeratorDashboardProps {}

const ModeratorDashboard: React.FunctionComponent<IModeratorDashboardProps> = (
  props
) => {
  const userProfile: User | undefined = useSelector(authUserProfileSelector);

  const committees = useSelector(committeesListSelector);
  const userCommittees = committees.filter((committee: Committee) =>
    userProfile?.committeeIds.includes(Number(committee.id))
  );
  const [selectedCommittee, setSelectedCommittee] = React.useState<number>(
    userProfile?.committeeIds[0] ?? 0
  );
  const committeeNames = userCommittees
    .map((committee) => committee.name)
    .join(', ');
  if (!userProfile) {
    return null;
  }
  return (
    <div>
      <div className="flex flex-row items-center justify-center">
        <NameCard className="flex flex-col items-center p-8 bg-slate-200 m-2 animate-slide-up">
          <p className="text-xl font-bold pb-1">Name</p>
          <p className="text-xl">{`${userProfile.firstName} ${
            userProfile.lastName || ''
          }`}</p>
        </NameCard>
        <NameCard
          className="flex flex-col items-center p-8 bg-slate-200 m-2 animate-slide-up"
          style={{ animationDelay: '0.2s' }}
        >
          <p className="text-xl font-bold pb-1">Committee</p>
          <p className="text-xl text-center">{`${
            committeeNames ?? 'No Committee assigned'
          }`}</p>
        </NameCard>
      </div>
      <div className="p-4">
        <SelectCommittee
          committees={userCommittees}
          committee={selectedCommittee}
          onChange={(value) => {
            setSelectedCommittee(value);
          }}
        />
      </div>
      <Resolutions committeeId={selectedCommittee} />
    </div>
  );
};

export default ModeratorDashboard;
