import { useSelector } from 'react-redux';

import { authUserRoleSelector } from '../store/selectors/auth.selectors';

const useAllowForRole = (roles: string[]): boolean => {
  const userRole: number | undefined = useSelector(authUserRoleSelector);
  if (!userRole) return false;
  return roles.includes(`${userRole}`);
};

export default useAllowForRole;
