import * as React from 'react';

import { Chip } from '@mui/material';

interface IStatusProps {
  status: boolean;
}

const Status: React.FunctionComponent<IStatusProps> = ({ status }) => {
  return (
    <Chip
      label={status ? 'Active' : 'Inactive'}
      color={status ? 'success' : 'error'}
    />
  );
};

export default Status;
