// write me a function that will take an object and list of keys that object has. And it will check if the value is empty string it will delete the key from the object.

import { roles } from '../appRoutes';
import { User } from '../models/user';

export const deleteEmptyKeys = (obj: Record<string, any>, keys: String[]) => {
  for (const key in obj) {
    if (keys.includes(key) && (obj[key] === '' || obj[key] === null)) {
      delete obj[key];
    }
  }
  return obj;
};

export const resetErrorOnFocus = ({
  key,
  validationErrors,
  setValidationErrors,
}: {
  key: string;
  validationErrors: Record<string, string | undefined>;
  setValidationErrors: (value: Record<string, string | undefined>) => void;
}) =>
  setValidationErrors({
    ...validationErrors,
    [key]: '',
  });

export const nullToEmpty = (obj: string | null | undefined) => {
  if (obj === null) {
    return '';
  }
  if (!obj) {
    return '';
  }
  return obj;
};

export const getFullName = (user: User | undefined) => {
  return user && `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
};

const roleIdToName = (): Record<string, string> =>
  Object.entries(roles).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [value]: key,
    };
  }, {});

export const getRoleNameFromId = (roleId: string | number | undefined): any => {
  return roleId && roleIdToName()[roleId];
};
