import React from 'react';

import { ClearRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

interface ISearchInputProps {
  placeholder: string;
  value: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}
const SearchInput: React.FC<ISearchInputProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      value={value}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <ClearRounded
              className="cursor-pointer"
              onClick={() => {
                onChange({
                  target: { value: '' },
                } as React.ChangeEvent<HTMLInputElement>);
              }}
            />
          </InputAdornment>
        ),
        style: { borderRadius: 50 }, // Set the border-radius
      }}
      onChange={onChange}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '50px',
            borderWidth: '2px',
          },
          '&:hover fieldset': {
            borderColor: 'primary.main',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'primary.main',
          },
        },
      }}
    />
  );
};

export default SearchInput;
