import * as React from 'react';
import { useSelector } from 'react-redux';

import SelectCommittee from '../components/fields/selectCommittee';
import { Committee } from '../models/committee';
import { activeCommitteesSelector } from '../store/selectors/committees.selectors';
import Resolutions from './resolutions';

interface IReportsProps {}

const Reports: React.FunctionComponent<IReportsProps> = (props) => {
  const committees: Committee[] = useSelector(activeCommitteesSelector);

  const [selectedCommittee, setSelectedCommittee] = React.useState<
    string | number
  >(committees?.[0]?.id ?? 0);
  return (
    <div>
      <div className="p-4">
        <SelectCommittee
          committees={committees}
          committee={Number(selectedCommittee)}
          onChange={(value) => {
            setSelectedCommittee(value);
          }}
        />
      </div>
      <Resolutions committeeId={Number(selectedCommittee)} />
    </div>
  );
};

export default Reports;
