import { MRT_ColumnDef } from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert } from '@mui/material';

import Table from '../components/table';
import { getStatusColumn } from '../components/table/statusColumn';
import { Group } from '../models/group';
import { AppDispatch } from '../store';
import {
  groupsErrorSelector,
  groupsListSelector,
  groupsLoadingSelector,
} from '../store/selectors/group.selectors';
import {
  createGroup,
  getGroups,
  resetGroupError,
  updateGroup,
} from '../store/slices/groups.slice';
import { resetErrorOnFocus } from '../utils/utils';
import {
  validateDescription,
  validateName,
  Validations,
} from '../utils/validations';

interface IGroupsProps {}

const Groups: React.FunctionComponent<IGroupsProps> = (props) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const groups: Group[] = useSelector(groupsListSelector);
  const error: string = useSelector(groupsErrorSelector);
  const isLoading: boolean = useSelector(groupsLoadingSelector);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getGroups({}));
  }, []);
  const columns = React.useMemo<MRT_ColumnDef<Group>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 60,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: ({ row }) => {
          return <div className="pt-2"></div>;
        },
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'name',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 150,
        muiEditTextFieldProps: {
          multiline: true,
          rows: 4,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'description',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      getStatusColumn<Group>(validationErrors),
    ],
    [validationErrors]
  );
  console.log('validationErrors', validationErrors);
  return (
    <div className="m-4">
      <Table
        isLoading={isLoading}
        columns={columns}
        data={groups}
        title="Groups"
        enableEditing={true}
        columnVisibility={{
          id: false,
        }}
        defaultValues={{
          status: true,
        }}
        additionalEditComponents={(row) =>
          error && <Alert severity="error">{error}</Alert>
        }
        resetOnFormClose={() => {
          setValidationErrors({});
          dispatch(resetGroupError());
        }}
        onCreateRowSave={async ({ values, table }) => {
          console.log({ values });
          const newValidationErrors = validateGroup(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          dispatch(
            createGroup({
              group: values,
              onSuccess: () => table.setCreatingRow(null),
            })
          );
        }}
        onEditRowSave={async ({ values, table }) => {
          console.log({ values });
          const newValidationErrors = validateGroup(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          dispatch(
            updateGroup({
              group: values,
              onSuccess: () => table.setEditingRow(null),
            })
          );
          //exit editing mode
        }}
      />
    </div>
  );
};

function validateGroup(group: Group) {
  return {
    name: validateName({ value: group.name, label: 'Name' }),
    description:
      group.description &&
      validateDescription({
        value: group.description,
        label: 'Description',
        maxLength: 300,
      }),
  };
}

export default Groups;
