import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Committee } from '../../models/committee';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

interface Committeestate {
  list: Committee[];
  isLoading: boolean;
  error: string;
}

const initialState: Committeestate = {
  list: [],
  isLoading: false,
  error: '',
};

const createCommittee = createAsyncThunk(
  'committee/create',
  async (
    { committee, onSuccess }: { committee: Committee; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<Committee>, Committee>(
        '/committees',
        committee
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Committee created successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getCommittees = createAsyncThunk(
  'committee/get',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<Committee[]>(
        '/committees?limit=0&offset=0'
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateCommittee = createAsyncThunk(
  'committee/update',
  async (
    { committee, onSuccess }: { committee: Committee; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      await API.patch<Committee, Committee>(
        `/committees/${committee.id}`,
        committee
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Committee updated successfully'));
      return { ...committee };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteCommittee = createAsyncThunk(
  'committee/delete',
  async ({ committee }: { committee: Committee }, thunkApi) => {
    try {
      await API.delete<Committee>(`/committees/${committee.id}`);
      thunkApi.dispatch(setToastMessage('Committee deleted successfully'));
      return { ...committee };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const committeeSlice = createSlice({
  name: 'committees',
  initialState,
  reducers: {
    resetCommitteeError: (state) => {
      state.error = '';
    },
  },
  extraReducers(builder) {
    // Create committees
    builder.addCase(createCommittee.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(createCommittee.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list.push(payload);
    });
    builder.addCase(createCommittee.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    //Get committees
    builder.addCase(getCommittees.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getCommittees.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = payload;
    });
    builder.addCase(getCommittees.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    //Update committees
    builder.addCase(updateCommittee.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateCommittee.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = state.list.map((committee) => {
        if (committee.id === payload.id) {
          return {
            ...committee,
            ...payload,
          };
        }
        return committee;
      });
    });
    builder.addCase(updateCommittee.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });

    //delete committee
    builder.addCase(deleteCommittee.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(deleteCommittee.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = state.list.filter(
        (committee) => committee.id !== payload.id
      );
    });
    builder.addCase(deleteCommittee.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
  },
});
const { resetCommitteeError } = committeeSlice.actions;

export {
  createCommittee,
  getCommittees,
  updateCommittee,
  deleteCommittee,
  initialState,
  resetCommitteeError,
};

export const committeeReducer = committeeSlice.reducer;
