import { RootState } from '../';

export const usersSelector = (state: RootState) => state.users.list;

export const usersIsLoadingSelector = (state: RootState) =>
  state.users.isLoading;

export const usersErrorSelector = (state: RootState) => state.users.error;

export const usersImportResponseSelector = (state: RootState) =>
  state.users.importResponse;

export const userByIdSelector = (id: number) => (state: RootState) => {
  return state.users.list.find((user) => user.id === id);
};

export const usersByIdsSelector = (ids: number[]) => (state: RootState) => {
  return state.users.list.filter((user) => ids.includes(user.id));
};
