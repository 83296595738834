import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import {
  authTokenSelector,
  authUserRoleSelector,
} from '../store/selectors/auth.selectors';

interface RoleBasedRouteProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
  allowedRoles,
  children,
}) => {
  const location = useLocation();
  const accessToken = useSelector(authTokenSelector);
  const currentUserRole = useSelector(authUserRoleSelector);
  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!allowedRoles.includes(`${currentUserRole}`)) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RoleBasedRoute;
