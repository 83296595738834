import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectEvent from '../components/fields/selectEvent';
import { PrimaryButton } from '../components/form/primaryButton';
import { AppDispatch } from '../store';
import { eventsSelector } from '../store/selectors/events.selectors';
import { getEvents } from '../store/slices/events.slice';
import Users from './users';

interface IEventUsersProps {}

const EventUsers: React.FunctionComponent<IEventUsersProps> = (props) => {
  const [eventId, setEventId] = useState<number>(0);

  const events = useSelector(eventsSelector);
  const dispatch = useDispatch<AppDispatch>();
  const isAdminSelection = eventId === -1;
  useEffect(() => {
    dispatch(getEvents({}));
  }, []);
  const selectedEvent = events.find((event) => event.id === eventId);
  return (
    <div
      className={`pt-4 flex flex-col h-screen ${
        !eventId ? 'items-center justify-center' : ''
      }`}
    >
      <div className={`p-4  bg-white ${!eventId ? 'w-6/12' : 'w-full'}`}>
        {!eventId && (
          <p className="mb-4 italic">Please select the event to view users.</p>
        )}
        {!isAdminSelection ? (
          <SelectEvent
            events={events}
            event={eventId}
            onChange={(value) => {
              setEventId(value);
            }}
          />
        ) : (
          <PrimaryButton
            onClick={() => {
              setEventId(0);
            }}
            label="Back"
          />
        )}
      </div>
      <div>
        {!eventId && (
          <PrimaryButton
            onClick={() => {
              setEventId(-1);
            }}
            label="View Admin Users"
          />
        )}
      </div>
      {eventId ? (
        <Users eventId={eventId} isActiveEvent={selectedEvent?.status} />
      ) : null}
    </div>
  );
};

export default EventUsers;
