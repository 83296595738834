import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { School } from '../../models/school';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

export type SchoolsState = {
  list: School[];
  isLoading: boolean;
  error: string;
};

const initialState: SchoolsState = {
  list: [],
  isLoading: false,
  error: '',
};

const getSchools = createAsyncThunk(
  'schools/list',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<School[]>>(
        '/schools?limit=0&offset=0'
      );
      console.log(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const createSchool = createAsyncThunk(
  'schools/create',
  async (
    { school, onSuccess }: { school: School; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<School>, School>(
        '/schools',
        school
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('School created successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateSchool = createAsyncThunk(
  'schools/update',
  async (
    { school, onSuccess }: { school: School; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      await API.patch<ApiResponse<School>, School>(
        `/schools/${school.id}`,
        school
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('School updated successfully'));
      return { ...school };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    resetSchoolError: (state) => {
      state.error = '';
    },
  },
  extraReducers(builder) {
    //Schools
    builder.addCase(getSchools.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getSchools.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list = payload;
      state.error = '';
    });
    builder.addCase(getSchools.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    //create school
    builder.addCase(createSchool.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(createSchool.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.error = '';
      state.list.push(payload);
    });
    builder.addCase(createSchool.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });

    //update school
    builder.addCase(updateSchool.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateSchool.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.error = '';
      state.list = state.list.map((school) => {
        if (school.id === payload.id) {
          return {
            ...school,
            ...payload,
          };
        }
        return school;
      });
    });
    builder.addCase(updateSchool.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

const { resetSchoolError } = schoolsSlice.actions;

export {
  getSchools,
  createSchool,
  updateSchool,
  initialState,
  resetSchoolError,
};

export const schoolsReducer = schoolsSlice.reducer;
