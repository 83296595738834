import { RootState } from '../';

export const resolutionsStateSelector = (state: RootState) => state.resolutions;

export const resolutionsByCommitteeIdSelector =
  (committeeId: number) => (state: RootState) =>
    resolutionsStateSelector(state).resolutionsByCommitteeId[committeeId];

export const resolutionsLoadingSelector = (state: RootState) =>
  resolutionsStateSelector(state).isLoading;

export const resolutionsErrorSelector = (state: RootState) =>
  resolutionsStateSelector(state).error;
