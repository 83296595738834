import React from 'react';

interface Props {
  text: string;
}

const RenderWithLineBreaks: React.FC<Props> = ({ text = '' }) => {
  const lines = text.split('\n');

  return (
    <div>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default RenderWithLineBreaks;
