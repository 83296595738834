import { RootState } from '../';

export const committeesStateSelector = (state: RootState) => state.committees;

export const committeesListSelector = (state: RootState) =>
  committeesStateSelector(state).list;

export const committeesLoadingSelector = (state: RootState) =>
  committeesStateSelector(state).isLoading;

export const committeesErrorSelector = (state: RootState) =>
  committeesStateSelector(state).error;
export const committeeByIdSelector = (id: number) => (state: RootState) =>
  committeesListSelector(state).find((committee) => committee.id === id);

export const activeCommitteesSelector = (state: RootState) =>
  committeesListSelector(state).filter(
    (committee) => committee.status === true
  );
