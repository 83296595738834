import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../store';
import { userByIdSelector } from '../store/selectors/users.selectors';
import { getUserById } from '../store/slices/users.slice';

const useUser = (userId: number) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(userByIdSelector(userId));
  useEffect(() => {
    if (userId) {
      dispatch(getUserById({ id: userId }));
    }
  }, [userId]);
  return user;
};

export default useUser;
