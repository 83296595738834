import { MRT_ColumnDef } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from '@emotion/styled';
import { CheckOutlined, Close } from '@mui/icons-material';
import { Alert, CircularProgress } from '@mui/material';

import { roles } from '../appRoutes';
import ConfirmAction from '../components/confirmAction';
import Table from '../components/table';
import useAllowForRole from '../hooks/useAllowForRole';
import useUser from '../hooks/useUser';
import { Resolution } from '../models/resolution';
import { AppDispatch } from '../store';
import { countriesSelector } from '../store/selectors/app.selectors';
import { groupsListSelector } from '../store/selectors/group.selectors';
import {
  resolutionsByCommitteeIdSelector,
  resolutionsErrorSelector,
  resolutionsLoadingSelector,
} from '../store/selectors/resolutions.selectors';
import { usersSelector } from '../store/selectors/users.selectors';
import {
  createResolution,
  getResolutionsByCommitteeId,
  resetResolutionsError,
  updateResolution,
} from '../store/slices/resolutions.slice';
import { resetErrorOnFocus } from '../utils/utils';
import {
  validateDescription,
  validateName,
  Validations,
} from '../utils/validations';
import Amendments from './amendments';

const CustomAlert = styled(Alert)`
  padding: 0px 6px;
  width: 130px;
`;
interface IResolutionsProps {
  committeeId: number;
}

const Resolutions: React.FunctionComponent<IResolutionsProps> = ({
  committeeId,
}) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const dispatch = useDispatch<AppDispatch>();
  const allowActions = useAllowForRole([roles.MODERATOR, roles.CHAIR]);
  const allowEditAction = useAllowForRole([roles.CHAIR]);
  const allowCreateAction = useAllowForRole([roles.DELEGATE]);
  const isLoading: boolean = useSelector(resolutionsLoadingSelector);
  const groups = useSelector(groupsListSelector);
  const countries = useSelector(countriesSelector);
  const error: string = useSelector(resolutionsErrorSelector);
  const groupOptions = useMemo(() => {
    return [
      ...groups
        .filter((group) => group.status)
        .map((group) => ({
          label: group.name,
          value: group.id,
        })),
      ...countries
        .filter((country) => country.status)
        .map((country) => ({
          label: country.name,
          value: country.id,
        })),
    ];
  }, [groups, countries]);
  const users = useSelector(usersSelector);
  const resolutions: Resolution[] =
    useSelector(resolutionsByCommitteeIdSelector(committeeId)) ?? [];
  useEffect(() => {
    committeeId && dispatch(getResolutionsByCommitteeId(committeeId));
  }, [committeeId]);
  const columns = React.useMemo<MRT_ColumnDef<Resolution>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 60,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: ({ row, table }) => {
          return <div className="pt-2"></div>;
        },
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'name',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 150,
        muiEditTextFieldProps: {
          multiline: true,
          rows: 4,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'description',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        size: 150,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: () => null,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <DisplayRepresenteeName
              row={row}
              users={users}
              groupOptions={groupOptions}
            />
          );
        },
      },
      {
        accessorKey: 'approval',
        header: 'Approval Status',
        size: 150,
        enableColumnFilter: false,
        enableSorting: false,
        Edit: () => null,
        Cell: ({ renderedCellValue, row }) => {
          if (row.original.approve === null) {
            return <CustomAlert severity="info">Pending</CustomAlert>;
          } else if (row.original.approve) {
            return <CustomAlert severity="success">Approved</CustomAlert>;
          } else {
            return <CustomAlert severity="error">Rejected</CustomAlert>;
          }
        },
      },
      {
        accessorKey: 'pass',
        header: 'Pass/Fail Status',
        size: 150,
        enableColumnFilter: false,
        enableSorting: false,
        Edit: () => null,
        Cell: ({ renderedCellValue, row }) => {
          if (row.original.pass === null) {
            return <CustomAlert severity="info">Pending</CustomAlert>;
          } else if (row.original.pass) {
            return <CustomAlert severity="success">Passed</CustomAlert>;
          } else {
            return <CustomAlert severity="error">Failed</CustomAlert>;
          }
        },
      },
      {
        accessorKey: 'documentUrl',
        header: 'Document url',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.documentUrl,
          helperText: validationErrors?.documentUrl,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'documentUrl',
              validationErrors,
              setValidationErrors,
            }),
        },
        Cell: ({ renderedCellValue, row }) => {
          return (
            <a
              href={row.original.documentUrl}
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            >
              View document
            </a>
          );
        },
      },
    ],
    [validationErrors]
  );

  const handleUpdateResolution = (partialResolution: Partial<Resolution>) => {
    dispatch(
      updateResolution({
        committeeId,
        partialResolution,
      })
    );
  };

  const isEditActionAllowed = ({ row }: { row: any }) => {
    if (row.original.approve === null || row.original.approve === true) {
      return row.original.pass === null;
    }
    return false;
  };
  return (
    <div className="m-4">
      <Table
        isLoading={isLoading}
        columns={columns}
        data={resolutions}
        title="Resolutions"
        enableEditing={allowActions}
        allowEditAction={isEditActionAllowed}
        enableGrouping={false}
        enableCreate={allowCreateAction}
        columnVisibility={{
          id: false,
        }}
        resetOnFormClose={() => {
          setValidationErrors({});
          dispatch(resetResolutionsError());
        }}
        additionalEditComponents={(row) =>
          error && <Alert severity="error">{error}</Alert>
        }
        rowActions={(row) => {
          if (allowEditAction) {
            if (
              row.original.pass === null &&
              row.original.approve !== null &&
              row.original.approve
            ) {
              return (
                <div className="flex">
                  <ConfirmAction
                    iconColor="success"
                    tooltip="Pass"
                    icon={<CheckOutlined />}
                    onConfirm={() =>
                      handleUpdateResolution({ id: row.id, pass: true })
                    }
                  />
                  <ConfirmAction
                    iconColor="error"
                    tooltip="Fail"
                    icon={<Close />}
                    onConfirm={() =>
                      handleUpdateResolution({
                        id: row.id,
                        pass: false,
                      })
                    }
                  />
                </div>
              );
            }
          } else {
            if (row.original.approve === null) {
              return (
                <div className="flex">
                  <div className="mr-2">
                    <ConfirmAction
                      iconColor="success"
                      tooltip="Approve"
                      icon={<CheckOutlined />}
                      onConfirm={() =>
                        handleUpdateResolution({ id: row.id, approve: true })
                      }
                    />
                    <ConfirmAction
                      iconColor="error"
                      tooltip="Reject"
                      icon={<Close />}
                      onConfirm={() =>
                        handleUpdateResolution({
                          id: row.original.id,
                          approve: false,
                        })
                      }
                    />
                  </div>
                </div>
              );
            }
          }
        }}
        onCreateRowSave={async ({ values, table }) => {
          values = { ...values, status: !!values.status };

          const newValidationErrors = validateResolution(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          dispatch(
            createResolution({
              committeeId,
              resolution: values,
              onSuccess: () => table.setCreatingRow(null),
            })
          );
        }}
        onEditRowSave={async ({ values, table }) => {
          values = { ...values, status: !!values.status };

          const newValidationErrors = validateResolution(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          if (values.pass === null) delete values.pass;
          setValidationErrors({});
          dispatch(
            updateResolution({
              committeeId,
              partialResolution: values,
              onSuccess: () => table.setEditingRow(null),
            })
          );
        }}
        renderDetailPanel={({ row }) => {
          return (
            <Amendments
              resolutionId={row.original?.id ?? ''}
              committeeId={committeeId}
              allowAddAction={row.original.pass === null}
              resolutionApproveStatus={row.original.approve}
            />
          );
        }}
      />
    </div>
  );
};

function validateResolution(resolution: Resolution) {
  return {
    name: validateName({
      value: resolution.name,
      maxLength: 50,
      label: 'Name',
    }),

    description:
      resolution.description &&
      validateDescription({
        value: `${resolution.description}`,
        maxLength: 100,
        label: 'Description',
      }),
    documentUrl: !Validations.validateRequired(resolution.documentUrl)
      ? 'Document url is required'
      : '',
  };
}

const DisplayRepresenteeName = ({ row, users, groupOptions }: any) => {
  const user = useUser(row.original.createdBy);
  if (user) {
    const representeeId = user.representeeId;
    const representeeName = groupOptions.find(
      (group: any) => group.value === representeeId
    )?.label;
    return <div>{representeeName}</div>;
  } else {
    return <CircularProgress />;
  }
};

export default Resolutions;
