import React from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import CountryFlag from '../components/countryFlag';
import RoleById from '../components/roleById';
import { User } from '../models/user';
import { countriesSelector } from '../store/selectors/app.selectors';
import { authUserProfileSelector } from '../store/selectors/auth.selectors';
import { committeesListSelector } from '../store/selectors/committees.selectors';
import { groupsListSelector } from '../store/selectors/group.selectors';
import { schoolsSelector } from '../store/selectors/schools.selectors';

type MyProfileProps = {};

const MyProfile: React.FC<MyProfileProps> = () => {
  const userInfo: User | undefined =
    useSelector(authUserProfileSelector) || undefined;
  const schools = useSelector(schoolsSelector);
  const commitees = useSelector(committeesListSelector);
  const groups = useSelector(groupsListSelector);
  const countries = useSelector(countriesSelector);
  const groupOptions = React.useMemo(() => {
    return [
      ...groups.filter((group) => group.status),
      ...countries.filter((country) => country.status),
    ];
  }, [groups, countries]);
  if (!userInfo) {
    return <CircularProgress />;
  }
  return (
    <div style={{ padding: '20px', margin: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="First Name" />
          <Value text={userInfo?.firstName ?? 'NA'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Last Name" />
          <Value text={userInfo?.lastName ?? 'NA'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Email" />
          <Value text={userInfo?.email ?? 'NA'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Phone No" />
          <Value text={userInfo?.phoneNumber ?? 'NA'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Gender" />
          <Value text={userInfo?.gender ?? 'NA'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Committee" />
          {commitees
            .filter((committee) =>
              userInfo?.committeeIds?.includes(Number(committee.id ?? 0))
            )
            .map((committee) => (
              <Value key={committee.id} text={committee.name} />
            ))}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Role" />
          <RoleById roleId={userInfo?.roleId} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="School" />
          <Value
            text={
              schools.find((school) => school.id === userInfo?.schoolId)
                ?.name ?? 'NA'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Label text="Country Representation" />
          <div className="flex flex-row">
            <CountryFlag country="IN" />
            <Value
              text={
                groupOptions.find(
                  (option) => option.id === userInfo?.representeeId
                )?.name ?? 'NA'
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const Label = ({ text }: { text: string }) => {
  return <p className="text-slate-500">{text}</p>;
};

const Value = ({ text }: { text: string }) => {
  return <p className="font-bold">{text}</p>;
};

export default MyProfile;
