export class Validations {
  static validateRequired(value: string) {
    value = value?.trim();
    return value && !!value.length;
  }

  static validateMaxLength({
    value,
    maxLength,
  }: {
    value: string;
    maxLength: number;
  }) {
    return value && value.length <= maxLength;
  }
  static isValidDate(value: string) {
    //TODO add date validations
    return !!value;
  }

  static validateEmail(value: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(value);
  }

  static validatePhoneNumber = (phoneNumber: string) => {
    const regex = /^\+?\d{10,15}$/;
    return regex.test(phoneNumber);
  };

  static validateTextWithoutSpecialCharacters = (text: string) => {
    const regex = /^[A-Za-z.\s]+$/;
    return regex.test(text);
  };
}

type ValidationRule = {
  required?: string;
  maxLength?: { value: number; message: string };
  minLength?: { value: number; message: string };
  pattern?: { regex: RegExp; message: string };
};

const validate = (value: string, rules: ValidationRule): string => {
  // Required check
  if (rules.required && !value) {
    return rules.required;
  }

  // Max length check
  if (rules.maxLength && value.length > rules.maxLength.value) {
    return rules.maxLength.message;
  }
  if (rules.minLength && value.length < rules.minLength.value) {
    return rules.minLength.message;
  }

  // Pattern check
  if (rules.pattern && !rules.pattern.regex.test(value)) {
    return rules.pattern.message;
  }

  // If all validations pass, return an empty string
  return '';
};

export const validateEmail = ({ value }: { value: string }) =>
  validate(value, {
    required: 'Email field is required',
    pattern: {
      regex: /^[a-zA-Z0-9._+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      message: 'Invalid email',
    },
  });

export const validatePhoneNumber = ({ value }: { value: string }) =>
  validate(value, {
    required: `Phone number is required`,
    pattern: {
      regex: /^\+?\d{10,15}$/,
      message: 'Invalid phone number',
    },
  });

export const validateName = ({
  value,
  label,
  maxLength,
  minLength = 2,
}: {
  value: string;
  label: string;
  maxLength?: number;
  minLength?: number;
}) =>
  validate(value?.trim(), {
    required: `${label} is required`,
    pattern: {
      regex: /^[A-Za-z.\s0-9]+$/,
      message: `Invalid ${label}, cannot container special characters`,
    },
    ...(minLength
      ? {
          minLength: {
            value: minLength,
            message: `${label} should be at least ${minLength} characters`,
          },
        }
      : {}),
    ...(maxLength
      ? {
          maxLength: {
            value: maxLength || 100,
            message: `${label} should not exceed ${maxLength} characters`,
          },
        }
      : {}),
  });

export const validateDescription = ({
  value,
  label,
  maxLength,
}: {
  value: string;
  label: string;
  maxLength?: number;
}) =>
  validate(value, {
    required: `${label} is required`,
    ...(maxLength
      ? {
          maxLength: {
            value: maxLength || 100,
            message: `${label} should not exceed ${maxLength} characters`,
          },
        }
      : {}),
  });
