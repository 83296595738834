import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { routePath } from '../appRoutes';
import { AppDispatch } from '../store';
import { authUserProfileSelector } from '../store/selectors/auth.selectors';
import { userLogout } from '../store/slices/auth.slice';
import { Colors } from '../utils/colors';
import { getFullName, getRoleNameFromId } from '../utils/utils';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(authUserProfileSelector);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMyProfile = () => {
    navigate(routePath.myAccount);
    handleClose();
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <NavigationItem label="Schedule" path="/schedule" />
        <NavigationItem label="Maps" path="/maps" />
        <NavigationItem label="FAQs" path="/faqs" />
        <Chip
          avatar={
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: Colors.primary,
                color: 'white',
              }}
              alt={`${userInfo?.firstName?.at(0) ?? ''}${
                userInfo?.lastName?.at(0) ?? ''
              } `}
              src="/static/images/avatar/1.jpg"
            />
          }
          label={`${getFullName(userInfo)} (${getRoleNameFromId(
            userInfo?.roleId
          )})`}
          variant="outlined"
          onClick={handleClick}
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleMyProfile}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            dispatch(userLogout());
            handleClose();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

interface INavigationItemProps {
  label: string;
  path: string;
}

const NavigationItem: React.FC<INavigationItemProps> = ({ label, path }) => {
  return (
    <NavLink className={'pl-8 text-gray-600'} to={path}>
      {label}
    </NavLink>
  );
};
