import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Slide, Snackbar } from '@mui/material';

import AppInit from './AppInit';
import { appRoutes } from './appRoutes';
import RoleBasedRoute from './components/roleBasedRoute';
import Sidebar from './components/sidebar';
import UnauthorizedScreen from './screens/unauthorized';
import { AppDispatch } from './store';
import { toastMessageSelector } from './store/selectors/app.selectors';
import { clearToastMessage } from './store/slices/app.slice';
import { getActiveEvent } from './store/slices/events.slice';

interface IAppProps {}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearToastMessage({}));
    setOpen(false);
  };
  const toastMessage = useSelector(toastMessageSelector);
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    toastMessage && setOpen(true);
    setTimeout(() => {
      setOpen(false);
      dispatch(clearToastMessage({}));
    }, 3000);
  }, [toastMessage]);
  React.useEffect(() => {
    dispatch(getActiveEvent({}));
  }, []);
  return (
    <Sidebar>
      <Routes>
        <Route path="/" element={<AppInit />}>
          {appRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.restrictedAccess ? (
                  <RoleBasedRoute allowedRoles={route.allowedRoles}>
                    {route.component}
                  </RoleBasedRoute>
                ) : (
                  route.component
                )
              }
            >
              {route.children &&
                route.children.map((childRoute, index) => {
                  return (
                    <Route
                      key={childRoute.path}
                      path={childRoute.path}
                      element={
                        childRoute.restrictedAccess ? (
                          <RoleBasedRoute
                            allowedRoles={childRoute.allowedRoles}
                          >
                            {childRoute.component}
                          </RoleBasedRoute>
                        ) : (
                          childRoute.component
                        )
                      }
                    />
                  );
                })}
            </Route>
          ))}
          <Route path="/unauthorized" element={<UnauthorizedScreen />} />
        </Route>
      </Routes>
      {toastMessage && (
        <Snackbar
          // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={toastMessage}
          TransitionComponent={Slide}
        />
      )}
    </Sidebar>
  );
};

const AppWrapper = ({ children }: { children: React.ReactNode[] }) => {
  const location = useLocation();
  return (
    <div>
      <Sidebar>{children}</Sidebar>
    </div>
  );
};

export default App;
