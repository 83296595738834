import * as React from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';

import FileViewerById from '../components/fileViewerById';
import { activeEventSelector } from '../store/selectors/events.selectors';

interface IMapsProps {}

const Maps: React.FunctionComponent<IMapsProps> = (props) => {
  const activeEvent = useSelector(activeEventSelector);
  return (
    <Paper className="m-4 flex flex-col p-8">
      <div className="flex flex-row items-start">
        <p className="text-3xl font-bold">Maps</p>
      </div>
      <FileViewerById id={activeEvent?.map} />
    </Paper>
  );
};

export default Maps;
