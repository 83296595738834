import moment from 'moment';
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';

import { CheckOutlined, Close } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';

import { roles } from '../appRoutes';
import ConfirmAction from '../components/confirmAction';
import { PrimaryButton } from '../components/form/primaryButton';
import useAllowForRole from '../hooks/useAllowForRole';
import { Amendment } from '../models/amendment';
import { AppDispatch } from '../store';
import { amendmentsByResolutionIdSelector } from '../store/selectors/amendments.selectors';
import {
  getAmendmentsByResolutionId,
  updateAmendment,
} from '../store/slices/amendments.slice';
import AddAmendmentForm from './addAmendmentForm';

interface IResolutionsProps {
  committeeId: number;
  resolutionId: number;
  allowAddAction: boolean;
  resolutionApproveStatus: boolean | null;
}

const DegreeEnum: any = {
  1: 'First',
  2: 'Second',
};

const Types: any = {
  1: 'Friendly',
  2: 'Strike',
  3: 'Add',
  4: 'Modify',
};

const degreeOptions = Object.keys(DegreeEnum).map((key) => {
  return { label: DegreeEnum[key], value: key };
});

const typesOptions = Object.keys(Types).map((key) => {
  return { label: Types[key], value: key };
});
const Amendments: React.FC<IResolutionsProps> = ({
  committeeId,
  resolutionId,
  allowAddAction,
  resolutionApproveStatus,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const isAllowedToCreateAmendment =
    useAllowForRole([roles.DELEGATE]) && resolutionApproveStatus === true;
  const amendments =
    useSelector(amendmentsByResolutionIdSelector(resolutionId)) ?? [];
  useEffect(() => {
    dispatch(getAmendmentsByResolutionId({ committeeId, resolutionId }));
  }, [committeeId, resolutionId]);
  const allowActions = useAllowForRole([roles.CHAIR]);
  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <p className="text-xl">Amendments</p>
        <div>
          {isAllowedToCreateAmendment && allowAddAction && (
            <AddAmendmentForm
              committeeId={committeeId}
              resolutionId={resolutionId}
            />
          )}
        </div>
      </div>
      {amendments.length === 0 && (
        <div className="flex items-center justify-center h-32">
          <p>No amendments are added yet</p>
        </div>
      )}
      <div className="flex flex-row gap-2 flex-wrap">
        {amendments.map((amendment) => {
          return (
            <AmendmentCard
              amendment={amendment}
              committeeId={committeeId}
              resolutionId={resolutionId}
              allowActions={allowActions}
              allowApproveAction={allowAddAction}
            />
          );
        })}
      </div>
    </div>
  );
};

const AmendmentCard: React.FC<{
  amendment: Amendment;
  committeeId: number;
  resolutionId: number;
  allowActions: boolean;
  allowApproveAction: boolean;
}> = ({
  amendment,
  committeeId,
  resolutionId,
  allowActions,
  allowApproveAction,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleUpdate = (status: boolean) => {
    dispatch(
      updateAmendment({
        amendment: { ...amendment, status },
        committeeId,
        resolutionId,
      })
    );
  };
  return (
    <Card
      raised
      sx={{
        minWidth: '400px',
        maxWidth: '400px',
        borderRadius: 2,
        boxShadow: 3,
        m: 2,
      }}
    >
      <CardContent
        className="flex flex-col justify-between h-full"
        style={{
          maxHeight: '204px',
        }}
      >
        <div className="flex flex-row items-center pb-1">
          <Alert severity="warning" icon={false} className="mr-2">
            Degree: {DegreeEnum[amendment.degreeId]}
          </Alert>
          <Alert severity="warning" icon={false}>
            Type: {Types[amendment.typeId]}
          </Alert>
        </div>
        <div
          className="flex flex-col items-start"
          style={{
            maxHeight: '150px',
            overflow: 'scroll',
            textOverflow: 'ellipsis',
          }}
        >
          <p dangerouslySetInnerHTML={{ __html: amendment.text }}></p>
        </div>
        <div className="flex flex-row justify-between items-center pt-2">
          <div className="flex flex-col">
            <Typography variant="caption" color="textSecondary">
              {amendment.createdAt !== null &&
                moment(amendment.createdAt).format('YYYY/MM/DD dddd hh:mm A')}
            </Typography>
            {amendment.status === null && (
              <Chip label="Pending" color="warning" variant="outlined" />
            )}
            {amendment.status === true && (
              <Chip label="Approved" color="success" />
            )}
            {amendment.status === false && (
              <Chip label="Rejected" color="error" />
            )}
          </div>
          <div className="flex flex-col">
            {allowApproveAction &&
              allowActions &&
              amendment.status === null && (
                <div>
                  <ConfirmAction
                    tooltip="Approve"
                    iconColor="success"
                    icon={<CheckOutlined />}
                    onConfirm={() => {
                      handleUpdate(true);
                    }}
                  />
                  <ConfirmAction
                    tooltip="Reject"
                    iconColor="error"
                    icon={<Close />}
                    onConfirm={() => {
                      handleUpdate(false);
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Amendments;
