import { RootState } from '../';

export const groupsStateSelector = (state: RootState) => state.groups;

export const groupsListSelector = (state: RootState) => state.groups.list ?? [];

export const groupsLoadingSelector = (state: RootState) =>
  groupsStateSelector(state).isLoading;

export const groupsErrorSelector = (state: RootState) =>
  groupsStateSelector(state).error;
