export type FAQ = {
  question: string;
  answer: string;
};
export const faqs = [
  {
    question: 'What happens if I can not preview the resolution?',
    answer:
      "Make sure to refresh the page. If it still doesn't work, try logging out and logging in again. If nothing works, inform the admin staff and the IT team will support you.",
  },
  {
    question: 'Where can I see the timings of lunch and other details?',
    answer:
      'Click on the “SCHEDULE” thumbnail at the top bar and view the details',
  },
  {
    question:
      "I don't receive a message in an instant from another delegate, what do I do?",
    answer:
      'In most cases, you should receive the message at that moment however, keep on refreshing the page and you can then view it. Keep a look-out on the numbers next to the chat icon. If you receive a new message, it will indicate that with a number.',
  },
  {
    question: 'How do I send amendments?',
    answer:
      'To send an amendment, message a moderator with a message like this: Strike/Amend/Add Clause number + explanation. Once you have sent the message, inform the admin staff that you have sent an amendment so that the chairs are aware of the amendment and can entertain it.',
  },
  {
    question: 'How do I preview the resolution and make notes?',
    answer:
      'Click on the eye icon once the resolution page opens up. You can make notes and view the resolution at the same time',
  },
  {
    question: 'What happens if I send inappropriate messages?',
    answer:
      'All chats are monitored by directors, chairs and admin staff. If at all we notice that you are misusing the application, the admin staff can ban your messaging rights and your director will be called.',
  },
  {
    question:
      'What do I do if my country delegation is different to what I have been assigned to?',
    answer: 'Inform the admin staff and the IT team will change the details.',
  },
  {
    question:
      'What if I want to read the DIAMUN DIGESTS or the DIAMUN NEWSLETTER?',
    answer:
      'To view DIAMUN Digests or the DIAMUN Newsletter, visit https://www.diamun.org/conference-newsletter.',
  },
  {
    question: 'How do I reset my password?',
    answer:
      'Once logged in, you receive a default password on your email. Click forgot password and change your password so that it is unique to you.',
  },
  {
    question:
      'How can I find the location of the different areas around the conference?',
    answer:
      'Click on the “MAPS” button to view the map, or alternatively visit https://www.diamun.org/2023-schedule for the full conference map.',
  },
  {
    question: 'Who do I contact if there are any technical issues?',
    answer: 'Please contact support@diamun.org for any technical issues.',
  },
];
