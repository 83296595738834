import { createContext } from 'react';

interface AdminUserContextProps {
  enableAdminUsers: boolean;
}

const initialValue = {
  enableAdminUsers: false,
};
export const AdminUserContext =
  createContext<AdminUserContextProps>(initialValue);
export const AdminUserContextProvider = AdminUserContext.Provider;
export const AdminUserContextConsumer = AdminUserContext.Consumer;
