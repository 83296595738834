import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../store';
import {
  chatMessagesForChatId,
  chatTotalMessagsSelector,
} from '../../store/selectors/chat.selectors';
import { getChatMessages } from '../../store/slices/chat.slice';
import { ChatMessageContainer } from '../chat';
import { MonitorChatContext } from './monitorChatUsersContext';

interface IMonitorChatMessagesProps {}
export const PER_PAGE = 20;
const MonitorChatMessages: React.FunctionComponent<
  IMonitorChatMessagesProps
> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [limit, setLimit] = React.useState(PER_PAGE);
  const { selectedChatId, selectedFromUserId, selectedToUserId } =
    React.useContext(MonitorChatContext);
  const messages =
    useSelector(chatMessagesForChatId(selectedChatId ?? '')) || [];
  const totalMessages = useSelector(
    chatTotalMessagsSelector(selectedChatId ?? '')
  );
  React.useEffect(() => {
    setLimit(PER_PAGE);
  }, [selectedChatId]);
  React.useEffect(() => {
    if (selectedChatId) {
      dispatch(
        getChatMessages({
          chatId: selectedChatId,
          userId: Number(selectedFromUserId),
          limit: limit,
          offset: 0,
        })
      );
    }
  }, [selectedChatId, selectedFromUserId, limit]);

  if (!selectedChatId) {
    return (
      <div className="flex flex-row items-center justify-center h-full">
        Please select users to view the chat
      </div>
    );
  }
  return (
    <div className="flex-1 h-full overflow-y-auto p-4 flex flex-col-reverse">
      <div
        id="scrollableDiv"
        style={{
          height: 'auto',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            console.log('next');
            setLimit(limit + PER_PAGE);
          }}
          style={{
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }} //To put endMessage and loader to the top.
          inverse={true} //
          hasMore={limit < totalMessages}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((msg) => (
            <ChatMessageContainer
              message={msg}
              key={msg.id}
              loggedInUserId={Number(selectedToUserId)}
              showDeliveryStatus={false}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MonitorChatMessages;
