import { createContext } from 'react';

interface MonitorChatContextType {
  selectedChatId?: string;
  selectedFromUserId?: string;
  selectedToUserId?: string;
  selectFromUser: (userId: string) => void;
  selectToUser: (userId: string) => void;
}

const initialValue = {
  selectedChatId: '',
  selectedFromUserId: '',
  selectedToUserId: '',
  selectFromUser: () => {},
  selectToUser: () => {},
};
export const MonitorChatContext =
  createContext<MonitorChatContextType>(initialValue);
export const MonitorChatContextProvider = MonitorChatContext.Provider;
export const MonitorChatContextConsumer = MonitorChatContext.Consumer;
