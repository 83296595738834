import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Amendment } from '../../models/amendment';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

export type AmendmentsState = {
  amendmentsByResolutionId: Record<string, Amendment[]>;
  isLoading: boolean;
  error: string;
};

const initialState = {
  amendmentsByResolutionId: {} as Record<string, Amendment[]>,
  isLoading: false,
  error: '',
};

const getAmendmentsByResolutionId = createAsyncThunk(
  'amendments/list',
  async (
    {
      committeeId,
      resolutionId,
    }: { committeeId: number; resolutionId: number },
    thunkApi
  ) => {
    try {
      const response = await API.get<ApiResponse<Amendment[]>>(
        `committees/${committeeId}/resolutions/${resolutionId}/amendments`
      );
      console.log(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const createAmendment = createAsyncThunk(
  'amendments/create',
  async (
    {
      amendment,
      committeeId,
      resolutionId,
      onSuccess,
    }: {
      amendment: Amendment;
      committeeId: number;
      resolutionId: number;
      onSuccess: () => void;
    },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<Amendment>, Amendment>(
        `committees/${committeeId}/resolutions/${resolutionId}/amendments`,
        amendment
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Amendment created successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateAmendment = createAsyncThunk(
  'amendments/update',
  async (
    {
      amendment,
      committeeId,
      resolutionId,
      onSuccess,
    }: {
      amendment: Amendment;
      committeeId: number;
      resolutionId: number;
      onSuccess?: () => void;
    },
    thunkApi
  ) => {
    try {
      const response = await API.patch<ApiResponse<Amendment>, Amendment>(
        `committees/${committeeId}/resolutions/${resolutionId}/amendments/${amendment.id}`,
        amendment
      );
      onSuccess && onSuccess();
      thunkApi.dispatch(setToastMessage('Amendment updated successfully'));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const deleteAmendment = createAsyncThunk(
  'amendments/delete',
  async (
    {
      amendmentId,
      committeeId,
      resolutionId,
      onSuccess,
    }: {
      amendmentId: number;
      committeeId: number;
      resolutionId: number;
      onSuccess: () => void;
    },
    thunkApi
  ) => {
    try {
      const response = await API.delete<ApiResponse<Amendment>>(
        `committees/${committeeId}/resolutions/${resolutionId}/amendments/${amendmentId}`
      );
      onSuccess && onSuccess();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const amendmentsSlice = createSlice({
  name: 'amendments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAmendmentsByResolutionId.pending, (state) => {
        state.isLoading = true;
        state.error = '';
        if (Object.keys(state.amendmentsByResolutionId).length === 0)
          state.amendmentsByResolutionId = {};
      })
      .addCase(getAmendmentsByResolutionId.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.isLoading = false;
        state.amendmentsByResolutionId[action.meta.arg.resolutionId] = payload;
        state.error = '';
      })
      .addCase(getAmendmentsByResolutionId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      .addCase(createAmendment.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.amendmentsByResolutionId[action.meta.arg.resolutionId] = [
          ...(state.amendmentsByResolutionId[action.meta.arg.resolutionId] ||
            []),
          payload,
        ];
      })
      .addCase(updateAmendment.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(updateAmendment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      .addCase(updateAmendment.fulfilled, (state, action) => {
        const resolutionId = action.meta.arg.resolutionId;
        const amendmentIndex = state.amendmentsByResolutionId[
          resolutionId
        ].findIndex(
          (amendment) => amendment.id === action.meta.arg.amendment.id
        );
        state.amendmentsByResolutionId[resolutionId][amendmentIndex] =
          action.meta.arg.amendment;
      });
  },
});

export { getAmendmentsByResolutionId, createAmendment, updateAmendment };
export const amendmentsReducer = amendmentsSlice.reducer;
