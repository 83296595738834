import { Form, Formik } from 'formik';
// Import React and any other necessary packages
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Divider from '@mui/material/Divider';

import { routePath } from '../appRoutes';
import { PrimaryButton } from '../components/form/primaryButton';
import { TextInput } from '../components/form/textInput';
import { AppDispatch } from '../store';
import {
  authErrorSelector,
  authIsLoadingSelector,
} from '../store/selectors/auth.selectors';
import { forgotPassword } from '../store/slices/auth.slice';

interface ForgotPasswordProps {
  // You can define props here if needed
}
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});
const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(authIsLoadingSelector);
  const errorMessage = useSelector(authErrorSelector);
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="mx-auto p-10 bg-white rounded-lg shadow-xl sm:w-4/12">
        <h2 className="text-2xl text-center mb-4">Reset Password</h2>
        <Divider />
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(
              forgotPassword({
                email: values.email,
                onSuccess: () => {
                  navigate(routePath.login);
                },
              })
            );
          }}
        >
          {() => (
            <Form>
              <div className="my-4 mt-8">
                <TextInput name="email" label="E-Mail Address" />
              </div>
              {errorMessage && (
                <p className="text-center text-red-400">{errorMessage}</p>
              )}
              <PrimaryButton
                label="Reset Password"
                type="submit"
                fullWidth
                action={() => {}}
                isLoading={isLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
