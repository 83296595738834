import axios, { AxiosError, AxiosResponse } from 'axios';

import { store } from './';

export const API_BASE_URL = process.env.REACT_APP_API_URL;
const HEADERS = {
  'Content-Type': 'application/json',
};

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: HEADERS,
});

axiosInstance.interceptors.request.use(function (config) {
  const state = store.getState();
  const token = state.auth.accessToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

type MetaData = {
  limit: number;
  offset: number;
  totalCount: number;
};
export interface ApiResponse<T> {
  id: string | number;
  data: T;
  message: string;
  metadata?: MetaData;
}

interface ApiErrorResponse {
  message: string;
}

class API {
  static async get<T>(path: string): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await axiosInstance.get<
        ApiResponse<T>
      >(path);
      return response.data.data ?? (response.data as T);
    } catch (error: any) {
      throw this.handleError(error);
    }
  }

  static async getWithMetaData<T>(path: string): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await axiosInstance.get<
        ApiResponse<T>
      >(path);
      return response.data as T;
    } catch (error: any) {
      throw this.handleError(error);
    }
  }

  static async post<T, B>(path: string, body: B): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await axiosInstance.post<
        ApiResponse<T>
      >(path, body);
      return response.data.data;
    } catch (error: any) {
      throw this.handleError(error);
    }
  }

  static async postMultipartFormData<T, B>(path: string, body: B): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await axiosInstance.post<
        ApiResponse<T>
      >(path, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.data;
    } catch (error: any) {
      throw this.handleError(error);
    }
  }

  static async patch<T, B>(path: string, body: B): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await axiosInstance.patch<
        ApiResponse<T>
      >(path, body);
      return response.data.data;
    } catch (error: any) {
      throw this.handleError(error);
    }
  }

  static async delete<T>(path: string): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> =
        await axiosInstance.delete<ApiResponse<T>>(path);
      return response.data.data;
    } catch (error: any) {
      throw this.handleError(error);
    }
  }

  private static handleError(error: AxiosError): string {
    const errorResponse = error.response?.data as ApiErrorResponse;
    return errorResponse?.message || error.message;
  }
}

export default API;
