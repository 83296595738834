import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roles } from '../../appRoutes';
import SelectCommittee from '../../components/fields/selectCommittee';
import { AppDispatch } from '../../store';
import { authUserProfileSelector } from '../../store/selectors/auth.selectors';
import { activeCommitteesSelector } from '../../store/selectors/committees.selectors';
import { activeEventSelector } from '../../store/selectors/events.selectors';
import {
  getChatIdMapSelector,
  getFromChatsSelector,
  getToChatUsersSelector,
} from '../../store/selectors/monitorChats.selectors';
import { getCountries } from '../../store/slices/app.slice';
import { getCommittees } from '../../store/slices/committees.slice';
import { getGroups } from '../../store/slices/groups.slice';
import { getAllUserChats } from '../../store/slices/monitorChats.slice';
import { getUsers } from '../../store/slices/users.slice';
import MonitorChatMessages from './monitorChatMessages';
import MonitorChatUsers from './MonitorChatUsers';
import { MonitorChatContextProvider } from './monitorChatUsersContext';

interface IMonitorChatsProps {
  committeeId?: number;
}

const ChatsHistory: React.FunctionComponent<IMonitorChatsProps> = ({
  committeeId = 0,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedChatId, setSelectedChatId] = React.useState<string>('');
  const [selectedFromUserId, setSelectedFromUserId] =
    React.useState<string>('');
  const [selectedToUserId, setSelectedToUserId] = React.useState<string>('');
  const committees = useSelector(activeCommitteesSelector);
  const selectFromUser = (userId: string) => {
    setSelectedFromUserId(userId);
  };
  const selectToUser = (userId: string) => {
    setSelectedToUserId(userId);
  };
  const fromUsers: any = useSelector(getFromChatsSelector);
  const toUsers: any = useSelector(getToChatUsersSelector);
  const chatIdMap: any = useSelector(getChatIdMapSelector);
  const activeEvent = useSelector(activeEventSelector);
  const [selectedCommitteeId, setCommitteeId] =
    React.useState<number>(committeeId);
  React.useEffect(() => {
    //Get users

    dispatch(getCommittees({}));
    dispatch(getGroups({}));
    dispatch(getCountries({}));
  }, []);
  React.useEffect(() => {
    dispatch(
      getUsers({
        queryParams: activeEvent ? `eventId=${activeEvent?.id}` : '',
      })
    );
  }, [activeEvent?.id]);
  React.useEffect(() => {
    dispatch(getAllUserChats({ committeeId: selectedCommitteeId }));
  }, [selectedCommitteeId]);
  React.useEffect(() => {
    if (selectedFromUserId && selectedToUserId) {
      setSelectedChatId(chatIdMap[`${selectedFromUserId}_${selectedToUserId}`]);
    }
  }, [selectedFromUserId, selectedToUserId]);
  const handleSelectFromUser = (userId: string) => {
    selectFromUser(userId);
  };
  const handleSelectToUser = (userId: string) => {
    selectToUser(userId);
  };
  console.log({
    selectedChatId,
    selectedFromUserId,
    selectedToUserId,
    fromUsers,
    toUsers,
  });
  return (
    <MonitorChatContextProvider
      value={{
        selectedChatId,
        selectedFromUserId,
        selectedToUserId,
        selectFromUser,
        selectToUser,
      }}
    >
      <div className="pt-4 flex flex-row justify-between items-center">
        <p className="text-3xl w-6/12">Monitoring Chats</p>
        {!committeeId && (
          <div className="w-6/12">
            <SelectCommittee
              committees={committees}
              committee={selectedCommitteeId}
              onChange={(value) => {
                setCommitteeId(value);
                selectFromUser('');
                selectToUser('');
                setSelectedChatId('');
              }}
            />
          </div>
        )}
      </div>
      <div
        className="flex flex-row w-full mt-8 divide-x rounded-lg border-2 bg-white"
        style={{ height: 'calc(100vh - 190px)' }}
      >
        <div className="w-3/12 flex-shrink-0 h-full">
          <p className="text-2xl flex flex-row items-center justify-center py-2">
            From User
          </p>
          <MonitorChatUsers
            userIds={Object.keys(fromUsers)}
            onUserSelect={handleSelectFromUser}
            selectedUserId={selectedFromUserId}
          />
        </div>
        <div className="flex-grow h-full">
          <MonitorChatMessages />
        </div>
        <div className="w-3/12 flex-shrink-0">
          {selectedFromUserId ? (
            <React.Fragment>
              <p className="text-2xl flex flex-row items-center justify-center py-2">
                To User
              </p>
              <MonitorChatUsers
                userIds={fromUsers[selectedFromUserId]}
                onUserSelect={handleSelectToUser}
                selectedUserId={selectedToUserId}
              />
            </React.Fragment>
          ) : (
            <div className="flex flex-row items-center justify-center w-full h-full">
              Select a user to view available chats
            </div>
          )}
        </div>
      </div>
    </MonitorChatContextProvider>
  );
};

const MonitorChats = () => {
  const userProfile = useSelector(authUserProfileSelector);
  const currentUserRole = `${userProfile?.roleId}`;
  const committeeId =
    currentUserRole === roles.SUPER_ADMIN || currentUserRole === roles.MODERATOR
      ? 0
      : userProfile?.committeeIds?.[0];
  return <ChatsHistory committeeId={committeeId} />;
};

export default MonitorChats;
