import { RootState } from '../';
import { groupsListSelector } from './group.selectors';

export const rolesSelector = (state: RootState) => state.app.roles;

export const countriesSelector = (state: RootState) => state.app.countries;

export const countryToISOCode2MapSelector = (state: RootState) =>
  state.app.countryToISOCode2Map;

export const filesByIdSelector = (id: number) => (state: RootState) =>
  filesSelector(state)?.[id] ?? '';

export const filesSelector = (state: RootState) => state.app.filesById;

export const toastMessageSelector = (state: RootState) =>
  state.app.toastMessage;

export const representeeByIdSelector = (id: number) => (state: RootState) =>
  [...groupsListSelector(state), ...countriesSelector(state)].find(
    (representee) => representee.id === id
  );
