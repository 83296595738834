import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { socketService } from '../services/socketService';
import msgNotification from '../sounds/msg_notification.mp3';
import { AppDispatch } from '../store';
import {
  authTokenSelector,
  authUserProfileSelector,
} from '../store/selectors/auth.selectors';
import { getLoggedInUserProfile } from '../store/slices/auth.slice';
import {
  addMessageFromSocket,
  addTypingStatus,
  ChatMessage,
  getActiveChats,
  removeTypingStatus,
  updateDeliveredStatus,
  updateReadStatus,
  updateSentStatus,
} from '../store/slices/chat.slice';

interface Message {
  fromUserId: string;
  id: string;
  message: string;
  chatId: string;
}
const useChat = () => {
  const typingTimeouts: Record<string, any> = {};
  const token = useSelector(authTokenSelector);
  const userProfile = useSelector(authUserProfileSelector);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    console.log('useChat', userProfile);
    userProfile && dispatch(getActiveChats({ userId: userProfile.id }));
  }, []);
  useEffect(() => {
    if (token && !socketService.isConnected()) {
      socketService.connect(`${process.env.REACT_APP_WSS_URL}`, token);
      socketService.onMessage('new message', (message: Message) => {
        const chatMessage: ChatMessage = {
          id: message.id,
          chatId: message.chatId,
          fromUserId: parseInt(message.fromUserId),
          toUserId: userProfile?.id || 0,
          message: message.message,
          status: 0,
          createdAt: new Date().toISOString(),
        };
        dispatch(addMessageFromSocket(chatMessage));
        console.log('new message', message);
        markMessageAsDelivered(message.id, message.chatId);
        if (userProfile?.notificationSound === true) {
          const sound = new Audio(msgNotification);
          sound.play();
        }
      });
      socketService.onMessage('delivered', (message: Message) => {
        console.log('delivered');
        dispatch(updateDeliveredStatus(message));
      });
      socketService.onMessage('read', (message: Message) => {
        console.log('read', message);
        dispatch(updateReadStatus(message));
      });
      socketService.onMessage('disconnect', (message: Message) => {
        console.log('disconnect');
      });
      socketService.onMessage('chat access', (message: Message) => {
        dispatch(getLoggedInUserProfile({ id: userProfile?.id ?? '' }));
      });
      socketService.onMessage('sent', (message: Message) => {
        dispatch(updateSentStatus(message));
      });
      socketService.onMessage('typing', (message: Message) => {
        console.log('typing', message);
        const chatId = message.chatId;
        dispatch(addTypingStatus({ chatId: message.chatId }));
        if (typingTimeouts[chatId]) {
          clearTimeout(typingTimeouts[chatId]);
        }
        typingTimeouts[chatId] = setTimeout(() => {
          dispatch(removeTypingStatus({ chatId: message.chatId }));
        }, 2000);
      });
    }
    return () => {
      socketService.disconnect();
    };
  }, [token]);
  const sendMessage = (
    message: string,
    to: number | undefined,
    chatId: string
  ) => {
    console.log('sendMessage', message, to, chatId);
    const messageId = uuidv4();
    socketService.sendMessage('new message', {
      message,
      toUserId: to,
      id: messageId,
      chatId: chatId,
    });
    dispatch(
      addMessageFromSocket({
        id: messageId,
        chatId: chatId,
        fromUserId: userProfile?.id || 0,
        toUserId: to || 0,
        message,
        status: 0,
        created_at: new Date().toISOString(),
      })
    );
  };
  const markMessageAsRead = (messageId: string | number, chatId: string) => {
    console.log('markMessageAsRead', messageId);
    socketService.sendMessage('read', { id: messageId });
    dispatch(updateReadStatus({ id: messageId, chatId: chatId }));
  };
  const markMessageAsDelivered = (
    messageId: string | number,
    chatId: string
  ) => {
    console.log('markMessageAsDelivered', messageId);
    socketService.sendMessage('delivered', { id: messageId });
    dispatch(updateDeliveredStatus({ id: messageId, chatId: chatId }));
  };
  const sendTypingEvent = (toUserId: number, chatId: string) => {
    console.log('sendTypingEvent', chatId, toUserId);
    socketService.sendMessage('typing', { chatId, toUserId: toUserId });
  };
  return {
    sendMessage,
    markMessageAsRead,
    sendTypingEvent,
  };
};

export default useChat;
