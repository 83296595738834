import * as React from 'react';
import { useSelector } from 'react-redux';

import { rolesSelector } from '../store/selectors/app.selectors';

interface IRoleByIdProps {
  roleId: number | undefined;
}

const RoleById: React.FunctionComponent<IRoleByIdProps> = ({ roleId }) => {
  const userRoles = useSelector(rolesSelector);
  const role = userRoles?.find((role) => role.id === roleId);
  return <p className="font-bold">{role?.name}</p>;
};

export default RoleById;
