import { RootState } from '../';

export const getFromChatsSelector = (state: RootState) =>
  state.monitorChats.fromUsers;

export const getToChatUsersSelector = (state: RootState) =>
  state.monitorChats.toUsers;
// export const getChatIdForUserCombination =
//   (fromUserId: number, toUserId: number) => (state: RootState) => {
//     const key = `${fromUserId.toString()}_${toUserId.toString()}`;
//     return state.monitorChats.chatIdMap?.[key];
//   };

export const getChatIdMapSelector = (state: RootState) =>
  state.monitorChats.chatIdMap;
