// src/socketService.ts
import { io, Socket } from 'socket.io-client';

class SocketService {
  private socket: Socket | undefined;

  public connect(url: string, token: string): void {
    this.socket = io(url, {
      path: process.env.REACT_APP_SOCKET_PATH,
      auth: {
        token,
      },
    });

    this.socket.on('connect', () => {
      console.log('Connected to the socket server');
    });
    this.socket.on('disconnect', () => {
      console.log('Disconnected from the socket server');
    });
    this.socket.on('error', (error: any) => {
      console.log('Error:', error);
    });
  }

  public isConnected(): boolean {
    return this.socket ? this.socket.connected : false;
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  public sendMessage(event: string, message: any): void {
    if (this.socket) {
      this.socket.emit(event, message);
    }
  }

  public onMessage(event: string, callback: (message: any) => void): void {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }
}

export const socketService = new SocketService();
