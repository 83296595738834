import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

import { AppDispatch } from '../store';
import {
  authErrorSelector,
  authIsLoadingSelector,
  authUserProfileSelector,
} from '../store/selectors/auth.selectors';
import { updateUser } from '../store/slices/users.slice';

interface ISettingsProps {}

const Settings: React.FunctionComponent<ISettingsProps> = (props) => {
  const userProfile = useSelector(authUserProfileSelector);
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(authIsLoadingSelector);
  const error = useSelector(authErrorSelector);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userProfile) {
      console.log('event.target.value', event.target.checked);
      dispatch(
        updateUser({
          user: {
            id: userProfile.id,
            eventId: userProfile.eventId,
            notificationSound: event.target.checked,
          },
        })
      );
    }
  };
  return (
    <div style={{ padding: '20px', margin: '20px' }} className="flex flex-col">
      <div className="flex flex-row ">
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              value={userProfile?.notificationSound}
              defaultChecked={userProfile?.notificationSound}
              onChange={handleChange}
              disabled={isLoading}
            />
          }
          label="Notification Sound"
        />
        {isLoading && <CircularProgress />}
      </div>
      {error && <Alert severity="error">{error}</Alert>}
    </div>
  );
};

export default Settings;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
