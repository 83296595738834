import { RootState } from '../';

export const authSelector = (state: RootState) => state.auth;

export const authIsLoadingSelector = (state: RootState) =>
  authSelector(state).isLoading;

export const authErrorSelector = (state: RootState) =>
  authSelector(state).error;

export const authTokenSelector = (state: RootState) =>
  authSelector(state).accessToken;

export const authUserIdSelector = (state: RootState) =>
  authSelector(state).userId;

export const authUserProfileSelector = (state: RootState) =>
  authSelector(state).userProfile;

export const authUserRoleSelector = (state: RootState) =>
  authSelector(state).userProfile?.roleId;
