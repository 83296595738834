import 'react-quill/dist/quill.snow.css';

import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import AddIcon from '@mui/icons-material/Add';
import {
    Alert, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Tooltip
} from '@mui/material';

import { PrimaryButton } from '../components/form/primaryButton';
import { Amendment } from '../models/amendment';
import { AppDispatch } from '../store';
import {
    amendmentsErrorSelector, isLoadingAmendmentsSelector
} from '../store/selectors/amendments.selectors';
import { createAmendment } from '../store/slices/amendments.slice';

interface IAddEmendmentFormProps {
  committeeId: number;
  resolutionId: number;
}
const AddAmendmentForm: React.FC<IAddEmendmentFormProps> = ({
  committeeId,
  resolutionId,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const isLoading = useSelector(isLoadingAmendmentsSelector);
  const error = useSelector(amendmentsErrorSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [amendmentData, setAmendmentData] = useState<Amendment>({
    text: '',
    degreeId: 1,
    typeId: 1,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: Amendment) => {
    console.log('Form Values:', values);
    dispatch(
      createAmendment({
        committeeId,
        resolutionId,
        amendment: values,
        onSuccess: handleClose,
      })
    );
  };

  return (
    <>
      <Tooltip title="Add Amendment">
        <IconButton onClick={handleOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle className="flex flex-row justify-around">
          Create Amendment
        </DialogTitle>
        <DialogContent>
          <AmendmentForm
            initialValues={amendmentData}
            onSubmit={handleSubmit}
            handleClose={handleClose}
            isLoading={isLoading}
            error={error}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

// Define the enums
const DegreeEnum = {
  1: 'First',
  2: 'Second',
};

const Types = {
  1: 'Friendly',
  2: 'Strike',
  3: 'Add',
  4: 'Modify',
};

// Define the Amendment model

interface AmendmentFormProps {
  isEditMode?: boolean;
  initialValues: Amendment;
  error?: string;
  isLoading?: boolean;
  onSubmit: (values: Amendment, actions: FormikHelpers<Amendment>) => void;
  handleClose: () => void;
}

const AmendmentForm: React.FC<AmendmentFormProps> = ({
  isEditMode = false,
  initialValues,
  error = '',
  isLoading = false,
  onSubmit,
  handleClose,
}) => {
  // Validation Schema
  const AmendmentSchema = Yup.object().shape({
    text: Yup.string().required('Text is required'),
    degreeId: Yup.number().required('Degree is required'),
    typeId: Yup.number().required('Type is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AmendmentSchema}
      onSubmit={onSubmit}
      enableReinitialize={isEditMode}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <Form>
          <label>Text</label>
          <Field name="text">
            {({ field }: { field: any }) => (
              <ReactQuill
                {...field}
                value={values.text}
                onChange={(newValue) => setFieldValue('text', newValue)}
              />
            )}
          </Field>
          <ErrorMessage
            name="text"
            component="div"
            className="text-red-500 text-xs"
          />

          <Field
            as={TextField}
            name="degreeId"
            select
            fullWidth
            label="Degree"
            margin="normal"
          >
            {Object.entries(DegreeEnum).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Field>
          <ErrorMessage
            name="degreeId"
            component="div"
            className="text-red-500 text-xs"
          />

          <Field
            as={TextField}
            name="typeId"
            select
            fullWidth
            label="Type"
            margin="normal"
          >
            {Object.entries(Types).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Field>
          <ErrorMessage
            name="typeId"
            component="div"
            className="text-red-500 text-xs"
          />
          {error && <Alert severity="error">{error}</Alert>}
          <div className="flex flex-row justify-end">
            <Button onClick={handleClose}>Cancel</Button>
            <PrimaryButton
              onClick={() => handleSubmit()}
              isLoading={isLoading}
              label="CREATE"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddAmendmentForm;
