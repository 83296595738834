import React from 'react';

import { ButtonProps, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

interface PrimaryButtonProps extends ButtonProps {
  label: string;
  isLoading?: boolean;
  action?: () => void; // This can be any function type depending on your needs
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  label,
  action,
  style,
  isLoading = false,
  ...otherProps
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={action}
      style={{ textTransform: 'none', ...style }}
      {...otherProps}
      // Spread any other props to allow for overriding and extending functionality
    >
      {isLoading ? (
        <CircularProgress
          color="inherit"
          size={20}
          style={{ marginRight: 10 }}
        />
      ) : (
        label
      )}
    </Button>
  );
};
