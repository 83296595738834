import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import API, { ApiResponse } from '../apiUtils';

export type Chat = {
  chatUsers: ChatUser[];
  messagesByChatId: Record<string, ChatMessage[]>;
  typingInChat: Record<string, boolean>;
  totalMessagesByChat: Record<string, number>;
};

export type ChatUser = {
  id: string;
  userId: number;
  name?: string;
  unreadMessageCount?: number;
};

export type ChatMessage = {
  id: number | string;
  chatId: string;
  fromUserId: number;
  toUserId: number;
  message: string;
  status: number;
  createdAt: string;
};

export const messageStatus = {
  PENDING: 0,
  SENT: 1,
  DELIVERED: 2,
  READ: 3,
};

const initialState: Chat = {
  chatUsers: [],
  messagesByChatId: {},
  typingInChat: {},
  totalMessagesByChat: {},
};

const getActiveChats = createAsyncThunk(
  'chats/getActiveChats',
  async ({ userId }: { userId: number }, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<ChatUser[]>>(
        `/users/${userId}/chats?limit=0&offset=0`
      );
      return response;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getChatMessages = createAsyncThunk(
  'chats/getChatMessages',
  async (
    {
      userId,
      chatId,
      limit = 0,
      offset = 0,
    }: { userId: number; chatId: string; limit?: number; offset?: number },
    thunkApi
  ) => {
    try {
      const response = await API.getWithMetaData<ApiResponse<ChatMessage[]>>(
        `/users/${userId}/chats/${chatId}/messages?limit=${limit}&offset=${offset}`
      );
      return {
        messages: response.data,
        total: response.metadata?.totalCount || 0,
      };
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetChat: () => initialState,
    addNewChat: (state, action) => {
      const userId = action.payload.userId;
      console.log(action.payload);
      const userChatExits: ChatUser | undefined = state.chatUsers.find(
        (c) => c.userId === userId
      );
      if (userChatExits?.id) {
        action.payload.onFailure(userChatExits.id);
        return;
      }
      delete action.payload.onFailure;
      state.chatUsers.push(action.payload);
    },
    addMessageFromSocket: (state, action) => {
      const payload: ChatMessage = action.payload;
      const chatId = payload.chatId;
      if (state.chatUsers.find((c) => c.id === chatId) === undefined) {
        state.chatUsers.push({ id: chatId, userId: payload.fromUserId });
      }
      if (state.messagesByChatId[chatId]) {
        state.messagesByChatId[chatId].push(payload);
      } else {
        state.messagesByChatId[chatId] = [payload];
      }
    },
    updateReadStatus: (state, action) => {
      const payload: ChatMessage = action.payload;
      const chatId = payload.chatId;
      const index =
        state.messagesByChatId[chatId]?.findIndex((m) => m.id === payload.id) ??
        -1;
      if (index !== -1) {
        state.messagesByChatId[chatId][index].status = messageStatus.READ;
      }
    },
    updateDeliveredStatus: (state, action) => {
      const payload: ChatMessage = action.payload;
      const chatId = payload.chatId;
      const index =
        state.messagesByChatId[chatId]?.findIndex((m) => m.id === payload.id) ??
        -1;
      if (index !== -1) {
        state.messagesByChatId[chatId][index].status = messageStatus.DELIVERED;
      }
    },
    updateSentStatus: (state, action) => {
      const payload: ChatMessage = action.payload;
      const chatId = payload.chatId;
      const index =
        state.messagesByChatId[chatId]?.findIndex((m) => m.id === payload.id) ??
        -1;
      if (index !== -1) {
        state.messagesByChatId[chatId][index].status = messageStatus.SENT;
      }
    },
    addTypingStatus: (state, action) => {
      const { chatId } = action.payload;
      state.typingInChat[chatId] = true;
    },
    removeTypingStatus: (state, action) => {
      const { chatId } = action.payload;
      state.typingInChat[chatId] = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getActiveChats.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.chatUsers = payload;
    });
    builder.addCase(getChatMessages.fulfilled, (state, action) => {
      const payload: any = action.payload.messages;
      console.log(payload);
      state.messagesByChatId[action.meta.arg.chatId] = (
        payload as ChatMessage[]
      ).reverse();
      if (!state.totalMessagesByChat) {
        state.totalMessagesByChat = {};
      }
      state.totalMessagesByChat[action.meta.arg.chatId] = action.payload.total;
    });
  },
});

const {
  addMessageFromSocket,
  updateReadStatus,
  addNewChat,
  resetChat,
  updateDeliveredStatus,
  updateSentStatus,
  addTypingStatus,
  removeTypingStatus,
} = chatSlice.actions;

export {
  getActiveChats,
  getChatMessages,
  addMessageFromSocket,
  updateReadStatus,
  addNewChat,
  resetChat,
  updateDeliveredStatus,
  updateSentStatus,
  addTypingStatus,
  removeTypingStatus,
};

export const chatReducer = chatSlice.reducer;
