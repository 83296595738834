import { RootState } from '../';

export const schoolsStateSelector = (state: RootState) => state.schools;

export const schoolsSelector = (state: RootState) =>
  schoolsStateSelector(state).list || [];

export const schoolsLoadingSelector = (state: RootState) =>
  schoolsStateSelector(state).isLoading;

export const schoolsErrorSelector = (state: RootState) =>
  schoolsStateSelector(state).error;

export const schoolByIdSelector = (id: number) => (state: RootState) =>
  schoolsSelector(state).find((school) => school.id === id);
