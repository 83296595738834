import './header.css';

import * as React from 'react';
import { useSelector } from 'react-redux';

import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { activeEventSelector } from '../../store/selectors/events.selectors';
import AccountMenu from '../accountMenu';
import FileViewerById from '../fileViewerById';
import { drawerWidth } from '../sidebar';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: 'white',
  color: 'black',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface IHeaderProps {
  open: boolean;
  onDrawerOpen: () => void;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  open,
  onDrawerOpen,
}) => {
  const activeEvent = useSelector(activeEventSelector);
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div className="w-12 h-12">
            <FileViewerById id={activeEvent?.logo} />
          </div>
          <p className="pl-2 text-slate-500">{activeEvent?.name}</p>
        </div>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
