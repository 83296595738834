import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { appRoutes, routePath } from '../appRoutes';
import {
  authTokenSelector,
  authUserIdSelector,
  authUserProfileSelector,
} from '../store/selectors/auth.selectors';
import { Colors } from '../utils/colors';
import AccountMenu from './accountMenu';
import Header from './header';

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar({ children }: { children: React.ReactNode[] }) {
  const userRoleId = useSelector(authUserProfileSelector)?.roleId ?? '';
  const theme = useTheme();
  const authToken = useSelector(authTokenSelector);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isUserLoggedIn = !!authToken;
  return (
    <div className="flex">
      {isUserLoggedIn && <Header open={open} onDrawerOpen={handleDrawerOpen} />}
      {isUserLoggedIn && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {appRoutes
              .filter(
                (route) =>
                  ![
                    routePath.myProfile,
                    routePath.faqs,
                    routePath.schedule,
                    routePath.maps,
                    routePath.myAccount,
                  ].includes(route.path)
              )
              .map((route) => {
                const isSelected = location.pathname === route.path;
                return (
                  route.allowedRoles.includes(`${userRoleId}`) && (
                    <ListItem
                      key={route.path}
                      disablePadding
                      sx={{
                        display: 'block',
                        background: isSelected ? Colors.primary : 'inherit',
                        color: isSelected ? 'white' : 'inherit',
                      }}
                      onClick={() => navigate(route.path)}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: isSelected ? 'white' : 'inherit',
                          }}
                        >
                          {route.icon && route.icon()}
                        </ListItemIcon>
                        <ListItemText
                          primary={route.label ?? route.path}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                );
              })}
          </List>
        </Drawer>
      )}
      <div className={`flex-1 ${isUserLoggedIn ? 'pt-12' : ''}`}>
        {children}
      </div>
    </div>
  );
}
