import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { createTheme, ThemeProvider } from '@mui/material';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';
import { Colors } from './utils/colors';

const theme = createTheme({
  //Add font
  typography: {
    fontFamily: ['"Open Sans", sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: Colors.primary, // e.g., '#556cd6'
    },

    // You can also add other color overrides here
  },
  // You can also customize other aspects of the theme, like typography, breakpoints, etc.
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
