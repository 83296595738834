import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { AppDispatch } from './store';
import { axiosInstance } from './store/apiUtils';
import {
  authTokenSelector,
  authUserIdSelector,
} from './store/selectors/auth.selectors';
import { getCountries, getRoles } from './store/slices/app.slice';
import { getLoggedInUserProfile } from './store/slices/auth.slice';
import { getCommittees } from './store/slices/committees.slice';
import { getActiveEvent } from './store/slices/events.slice';
import { getGroups } from './store/slices/groups.slice';
import { getSchools } from './store/slices/schools.slice';

interface IAppInitProps {}

const AppInit: React.FunctionComponent<IAppInitProps> = (props) => {
  const accessToken = useSelector(authTokenSelector);
  const userId = useSelector(authUserIdSelector);
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (accessToken) {
      axiosInstance.interceptors.request.use(function (config) {
        const token = accessToken;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      });
      dispatch(getCountries({}));
      dispatch(getRoles({}));
      dispatch(getCommittees({}));
      dispatch(getGroups({}));
      dispatch(getSchools({}));
      dispatch(getActiveEvent({}));
    }
  }, [accessToken]);
  React.useEffect(() => {
    if (userId) dispatch(getLoggedInUserProfile({ id: userId }));
  }, [userId]);
  return (
    <div className="p-4">
      <Outlet />
    </div>
  );
};

export default AppInit;
