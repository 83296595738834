import React, { useEffect, useRef, useState } from 'react';

import data, { EmojiMartData } from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { IconButton } from '@mui/material';

interface ChatInputProps {
  onEmojiSelect: (emoji: EmojiMartData) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onEmojiSelect }) => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null); // Ref for the emoji picker container

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <div>
      <IconButton onClick={togglePicker} className="border-1 rounded-md">
        <InsertEmoticonIcon />
      </IconButton>
      {showPicker && (
        <div
          ref={pickerRef}
          style={{ position: 'absolute', bottom: '40px', zIndex: 2 }}
        >
          <Picker data={data} onEmojiSelect={onEmojiSelect} />
        </div>
      )}
    </div>
  );
};

export default ChatInput;
