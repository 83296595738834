import { Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Alert } from '@mui/material';

import { PrimaryButton } from '../components/form/primaryButton';
import { TextInput } from '../components/form/textInput';
import { AppDispatch } from '../store';
import {
  authErrorSelector,
  authIsLoadingSelector,
  authUserIdSelector,
} from '../store/selectors/auth.selectors';
import { updatePassword } from '../store/slices/auth.slice';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .notOneOf(
      [Yup.ref('oldPassword')],
      'Passowrd must be different from old password'
    ),
  //Add condition to check if the newPassword and confirmNewPassword are same
  confirmNewPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

interface IUpdatePasswordProps {}

const UpdatePassword: React.FunctionComponent<IUpdatePasswordProps> = (
  props
) => {
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector(authUserIdSelector);
  const isLoading = useSelector(authIsLoadingSelector);
  const error: string = useSelector(authErrorSelector);
  return (
    <div>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const updatePasswordBody = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          };
          dispatch(
            updatePassword({
              id: userId,
              updatePassword: updatePasswordBody,
              onSuccess: () => {
                resetForm();
              },
            })
          );
        }}
      >
        {() => (
          <Form>
            <div className="my-4 mt-8">
              <TextInput
                name="oldPassword"
                label="Current Password"
                type="password"
              />
            </div>
            <div className="my-4 mt-8">
              <TextInput
                name="newPassword"
                label="New Password"
                type="password"
              />
            </div>
            <div className="my-4 mt-8">
              <TextInput
                name="confirmNewPassword"
                label="Confirm New Password"
                type="password"
              />
            </div>
            {error && (
              <Alert severity="error" className="my-4">
                {error}
              </Alert>
            )}
            <PrimaryButton
              label="Update Password"
              type="submit"
              fullWidth
              action={() => {}}
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePassword;
