import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import { amendmentsReducer } from './slices/amendments.slice';
import { appReducer } from './slices/app.slice';
import { authReducer } from './slices/auth.slice';
import { chatReducer } from './slices/chat.slice';
import { committeeReducer } from './slices/committees.slice';
import { eventsReducer } from './slices/events.slice';
import { groupsReducer } from './slices/groups.slice';
import { monitorChatsReducer } from './slices/monitorChats.slice';
import { resolutionsReducer } from './slices/resolutions.slice';
import { schoolsReducer } from './slices/schools.slice';
import { userReducer } from './slices/users.slice';

const persistConfig = {
  key: 'DIAMUN',
  storage,
};

export const rootReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  app: appReducer,
  events: eventsReducer,
  schools: schoolsReducer,
  groups: groupsReducer,
  committees: committeeReducer,
  resolutions: resolutionsReducer,
  amendments: amendmentsReducer,
  chats: chatReducer,
  monitorChats: monitorChatsReducer,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'], // Ignore serializability check for persist/PERSIST action
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
