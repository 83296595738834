import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert } from '@mui/material';

import CountryFlag from '../components/countryFlag';
import Status from '../components/status';
import Table from '../components/table';
import { getStatusColumn } from '../components/table/statusColumn';
import { Country } from '../models/country';
import { School } from '../models/school';
import { AppDispatch } from '../store';
import { countriesSelector } from '../store/selectors/app.selectors';
import {
  schoolsErrorSelector,
  schoolsLoadingSelector,
  schoolsSelector,
} from '../store/selectors/schools.selectors';
import {
  createSchool,
  getSchools,
  resetSchoolError,
  updateSchool,
} from '../store/slices/schools.slice';
import { resetErrorOnFocus } from '../utils/utils';
import {
  validateDescription,
  validateName,
  Validations,
} from '../utils/validations';

interface IGroupsProps {}

const Schools: React.FunctionComponent<IGroupsProps> = (props) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const dispatch = useDispatch<AppDispatch>();
  const countries = useSelector(countriesSelector);
  const isLoading: boolean = useSelector(schoolsLoadingSelector);
  const error: string = useSelector(schoolsErrorSelector);
  const schools: School[] = useSelector(schoolsSelector);
  useEffect(() => {
    dispatch(getSchools({}));
  }, []);
  const countryOptions = React.useMemo(() => {
    return countries.map((country) => ({
      label: country.name,
      value: country.id,
    }));
  }, [countries]);
  const columns = React.useMemo<MRT_ColumnDef<School>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 60,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: ({ row, table }) => {
          return <div className="pt-2"></div>;
        },
      },
      // name
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'name',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      // country
      {
        accessorKey: 'countryId',
        header: 'Country',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.countryId,
          helperText: validationErrors?.countryId,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'countryId',
              validationErrors,
              setValidationErrors,
            }),
        },
        Cell: ({ row }: { row: MRT_Row<School> }) => (
          <CountryFlag
            country={
              countries.find(
                (country: Country) => country.id === row.original.countryId
              )?.name ?? ''
            }
          />
        ),
        editVariant: 'select',
        editSelectOptions: countryOptions,
        filterVariant: 'select',
        filterSelectOptions: countryOptions,
      },

      {
        accessorKey: 'address',
        header: 'Address',
        size: 150,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.address,
          helperText: validationErrors?.address,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'address',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      getStatusColumn<School>(validationErrors),
    ],
    [validationErrors]
  );
  console.log('validationErrors', validationErrors);
  return (
    <div className="m-4">
      <Table
        isLoading={isLoading}
        columns={columns}
        data={schools}
        title="Schools"
        enableEditing={true}
        enableGrouping={true}
        additionalEditComponents={(row) =>
          error && <Alert severity="error">{error}</Alert>
        }
        columnVisibility={{
          id: false,
        }}
        resetOnFormClose={() => {
          setValidationErrors({});
          dispatch(resetSchoolError());
        }}
        onCreateRowSave={async ({ values, table }) => {
          values = { ...values, status: !!values.status };
          console.log(values);
          const newValidationErrors = validateSchool(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          dispatch(
            createSchool({
              school: values,
              onSuccess: () => table.setCreatingRow(null),
            })
          );
        }}
        onEditRowSave={async ({ values, table }) => {
          values = { ...values, status: !!values.status };
          console.log(values);
          const newValidationErrors = validateSchool(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          dispatch(
            updateSchool({
              school: values,
              onSuccess: () => table.setEditingRow(null),
            })
          );
        }}
      />
    </div>
  );
};

function validateSchool(school: School) {
  return {
    name: validateName({
      value: school.name,
      maxLength: 50,
      label: 'School name',
    }),
    countryId: !Validations.validateRequired(
      school.countryId ? `${school.countryId}` : ''
    )
      ? 'Country is required'
      : '',
    address: validateDescription({
      value: school.address,
      maxLength: 100,
      label: 'Address',
    }),
  };
}

export default Schools;
