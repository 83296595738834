import * as React from 'react';

import { List, ListItem, ListItemAvatar } from '@mui/material';

import useChatName from '../../hooks/useChatName';
import { ChatUserInfo } from '../chat/chatUsers';

interface IMonitorChatUsersProps {
  userIds: string[];
  selectedUserId: string;
  onUserSelect: (userId: string) => void;
}

const MonitorChatUsers: React.FunctionComponent<IMonitorChatUsersProps> = ({
  userIds,
  selectedUserId,
  onUserSelect,
}) => {
  return (
    <List
      sx={{
        overflow: 'auto',
        height: 'calc(100vh - 250px)',
      }}
    >
      {userIds?.map((userId) => {
        return (
          <MonitorChatListItem
            key={userId}
            userId={userId}
            onUserSelect={onUserSelect}
            isSelected={userId === selectedUserId}
          />
        );
      })}
    </List>
  );
};

const MonitorChatListItem = ({
  userId,
  isSelected,
  onUserSelect,
}: {
  userId: string;
  isSelected: boolean;
  onUserSelect: (userId: string) => void;
}) => {
  const userName = useChatName(Number(userId));
  return (
    <ListItem
      className="hover:bg-gray-300 border-b-2 h-16 border-gray-200"
      key={userId}
      sx={{
        cursor: 'pointer',
        backgroundColor: isSelected ? 'lightgrey' : 'inherit',
      }}
      onClick={() => {
        onUserSelect(userId);
      }}
    >
      <ListItemAvatar>
        <ChatUserInfo label={userName} />
      </ListItemAvatar>
      {userName}
    </ListItem>
  );
};

export default MonitorChatUsers;
