import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Committee } from '../../models/committee';

export const SelectCommittee = ({
  committees,
  committee,
  onChange,
}: {
  committees: Committee[];
  committee: number;
  onChange: (value: number) => void;
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(Number(event.target.value));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Committee</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={`${committee}`}
        label="Committee"
        onChange={handleChange}
      >
        {committees.map((committee) => (
          <MenuItem value={committee.id}>{committee.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCommittee;
