import { Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Add, Edit } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';

import { roles } from '../appRoutes';
import useEventsOptions from '../hooks/useEventsOptions';
import { User } from '../models/user';
import { AdminUserContext } from '../screens/adminUserContext';
import { AppDispatch } from '../store';
import {
  countriesSelector,
  rolesSelector,
} from '../store/selectors/app.selectors';
import { committeesListSelector } from '../store/selectors/committees.selectors';
import { groupsListSelector } from '../store/selectors/group.selectors';
import { schoolsSelector } from '../store/selectors/schools.selectors';
import {
  usersErrorSelector,
  usersIsLoadingSelector,
} from '../store/selectors/users.selectors';
import {
  createUser,
  resetUserError,
  updateUser,
} from '../store/slices/users.slice';
import { deleteEmptyKeys } from '../utils/utils';
import { PrimaryButton } from './form/primaryButton';
import SelectInput from './form/selectInput';
import { TextInput } from './form/textInput';

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  roleId: Yup.string().required('Required'),
  schoolId: Yup.string()
    .nullable()
    .when('roleId', ([roleId], schema) => {
      return roles.DELEGATE === `${roleId}`
        ? schema.required('Required')
        : schema;
    }),
  representeeId: Yup.string()
    .nullable()
    .when('roleId', ([roleId], schema) => {
      return roles.DELEGATE === `${roleId}`
        ? schema.required('Required')
        : schema;
    }),
  gender: Yup.string().required('Required'),
  eventId: Yup.string()
    .nullable()
    .when('roleId', ([roleId], schema) => {
      return ![roles.SUPER_ADMIN].includes(`${roleId}`)
        ? schema.required('Required')
        : schema;
    }),
  phoneNumber: Yup.string()
    .nullable() // Allow null (empty) values
    .matches(/^\+?\d{8,15}$/, 'Invalid phone number format (e.g. +1234567890)'),
});
interface IAddUserFormProps {
  user?: User;
  isEditMode?: boolean;
  onlyAdmin?: boolean;
  onClose: () => void;
}

const AddUserForm: React.FunctionComponent<IAddUserFormProps> = ({
  user,
  isEditMode,
  onlyAdmin = false,
  onClose,
}) => {
  const error = useSelector(usersErrorSelector);
  const isLoading = useSelector(usersIsLoadingSelector);
  const allRoles = useSelector(rolesSelector);
  const schools = useSelector(schoolsSelector);
  const groups = useSelector(groupsListSelector);
  const countries = useSelector(countriesSelector);
  const committees = useSelector(committeesListSelector);
  const roleOptions =
    allRoles?.map((role) => ({
      label: role.name,
      value: role.id,
    })) ?? [];
  const schoolOptions = React.useMemo(() => {
    return schools
      .filter((school) => school.status)
      .map((school) => ({
        label: school.name,
        value: school.id,
      }));
  }, [schools]);
  const groupOptions = React.useMemo(() => {
    return [
      ...groups
        .filter((group) => group.status)
        .map((group) => ({
          label: group.name,
          value: group.id,
        })),
      ...countries
        .filter((country) => country.status)
        .map((country) => ({
          label: country.name,
          value: country.id,
        })),
    ];
  }, [groups, countries]);
  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    roleId: onlyAdmin ? Number(roles.SUPER_ADMIN) : '',
    schoolId: '',
    eventId: '',
    representeeId: '',
    phoneNumber: '',
    committeeId: '',
    committeeIds: [],
    gender: '',
    status: true,
  };
  const dispatch = useDispatch<AppDispatch>();
  const singleCommitteeRoles = [
    roles.DELEGATE,
    roles.CHAIR,
    roles.DEPUTY_CHAIR,
  ];
  const eventsOptions = useEventsOptions();
  React.useEffect(() => {
    dispatch(resetUserError());
  }, []);
  return (
    <div>
      <Formik
        initialValues={
          user
            ? {
                ...user,
                committeeId: singleCommitteeRoles.includes(`${user?.roleId}`)
                  ? user?.committeeIds[0]
                  : '',
              }
            : initialValues
        }
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);

          const body: any = {
            ...values,
            committeeIds: singleCommitteeRoles.includes(`${values.roleId}`)
              ? values?.committeeId
                ? [Number(values.committeeId)]
                : []
              : values.committeeIds?.map((id) => Number(id)) ?? [],
          };
          const sanitizedBody: any = deleteEmptyKeys(body, [
            'representeeId',
            'schoolId',
            'committeeIds',
            'eventId',
            'schoolId',
          ]);
          if (
            [roles.SUPER_ADMIN, roles.OBSERVER].includes(
              `${sanitizedBody.roleId}`
            )
          ) {
            values.committeeIds = [];
          }

          if (user) {
            dispatch(
              updateUser({
                user: sanitizedBody,
                onSuccess: () => {
                  onClose();
                },
              })
            );
          } else {
            dispatch(
              createUser({
                user: sanitizedBody,
                onSuccess: () => {
                  onClose();
                },
              })
            );
          }
        }}
      >
        {({ values, handleSubmit, errors }) => {
          console.log({ values, errors });
          const committeeOptions = committees
            .filter((committee) => committee.eventId === values.eventId)
            .filter((committee) => committee.status)
            .map((committee) => ({
              label: committee.name,
              value: committee.id,
            }));
          const isAdmin = [roles.SUPER_ADMIN].includes(`${values.roleId}`);
          return (
            <Form>
              <div className="my-4 ">
                <TextInput name="firstName" label="First Name" required />
              </div>
              <div className="my-4 ">
                <TextInput name="lastName" label="Last Name" required />
              </div>
              <div className="my-4 ">
                <TextInput name="email" label="Email" required />
              </div>
              {!isAdmin && (
                <div className="my-4 ">
                  <SelectInput
                    name="roleId"
                    label="Role"
                    required
                    options={roleOptions}
                  />
                </div>
              )}
              {!isEditMode && !isAdmin && (
                <div className="my-4 ">
                  <SelectInput
                    name="eventId"
                    label="Event"
                    required
                    options={eventsOptions}
                  />
                </div>
              )}
              {!isAdmin && (
                <div className="my-4 ">
                  <SelectInput
                    name="schoolId"
                    label="School"
                    required={roles.DELEGATE === `${values.roleId}`}
                    options={schoolOptions}
                  />
                </div>
              )}
              {[roles.DELEGATE].includes(`${values.roleId}`) && (
                <div className="my-4 ">
                  <SelectInput
                    name="representeeId"
                    label="Country/Group"
                    required={roles.DELEGATE === `${values.roleId}`}
                    options={groupOptions}
                  />
                </div>
              )}
              <div className="my-4">
                <TextInput name="phoneNumber" label="Phone Number" />
              </div>
              <div className="my-4 ">
                <SelectInput
                  name="gender"
                  label="Gender"
                  required
                  options={genderOptions}
                />
              </div>
              {![roles.SUPER_ADMIN, roles.OBSERVER].includes(
                `${values.roleId}`
              ) &&
                (singleCommitteeRoles.includes(`${values.roleId}`) ? (
                  <div className="my-4 ">
                    <SelectInput
                      name="committeeId"
                      label="Committees"
                      options={committeeOptions}
                    />
                  </div>
                ) : (
                  <div className="my-4 ">
                    <SelectInput
                      name="committeeIds"
                      label="Committees"
                      multiple={true}
                      options={committeeOptions}
                    />
                  </div>
                ))}
              <div className="my-4 ">
                <SelectInput
                  name="status"
                  label="Status"
                  required
                  options={statusOptions}
                />
              </div>
              <div>{error && <Alert severity="error">{error}</Alert>}</div>
              <div className="flex flex-row items-center justify-end">
                <Button onClick={onClose}>CANCEL</Button>
                <PrimaryButton
                  label="SAVE"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const AddUserDialog = ({ user }: { user?: User }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { enableAdminUsers } = React.useContext(AdminUserContext);
  const handleClose = () => {
    setOpen(false);
  };
  const isEditMode = !!user;
  return (
    <React.Fragment>
      <Tooltip title="Import users">
        <IconButton onClick={() => setOpen(true)}>
          {!enableAdminUsers && (isEditMode ? <Edit /> : <Add />)}
          {enableAdminUsers && <Add />}
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
          <DialogTitle className="flex flex-row justify-around">
            {!enableAdminUsers && (isEditMode ? 'Edit User' : 'Add User')}
            {enableAdminUsers && 'Create Super Admin User'}
          </DialogTitle>
          <DialogContent>
            <AddUserForm
              onlyAdmin={enableAdminUsers}
              onClose={handleClose}
              user={user}
              isEditMode={isEditMode}
            />
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default AddUserDialog;
