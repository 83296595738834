import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import API, { ApiResponse } from '../apiUtils';
import { ChatMessage } from './chat.slice';

export type MonitorChatUser = {
  id: string;
  user1Id: number;
  user2Id: number;
};

export type MonitorChats = {
  isLoading: boolean;
  error: string;
  fromUsers: Record<number, number[]>;
  toUsers: Record<number, number[]>;
  messagesByChatId: Record<string, ChatMessage[]>;
  chatIdMap: Record<string, string>;
};

const initialState = {
  isLoading: false,
  error: '',
  fromUsers: {},
  toUsers: {},
  messagesByChatId: {},
  chatIdMap: {},
};

const getAllUserChats = createAsyncThunk(
  'monitorChats/getAllUserChats',
  async ({ committeeId }: { committeeId?: number }, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<MonitorChatUser[]>>(
        `/users/chats?limit=0&offset=0${
          committeeId ? `&committeeId=${committeeId}` : ''
        }`
      );
      return response;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const getChatMessagesForMonitoring = createAsyncThunk(
  'monitorChats/getChatMessagesForMonitoring',
  async (
    {
      userId,
      chatId,
      offset = 0,
      limit = 0,
    }: { userId: number; chatId: string; offset?: number; limit?: number },
    thunkApi
  ) => {
    try {
      const response = await API.get<ApiResponse<ChatMessage[]>>(
        `/users/${userId}/chats/${chatId}/messages?limit=${limit}&offset=${offset}`
      );
      return { response, chatId };
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const monitorChatsSlice = createSlice({
  name: 'monitorChats',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUserChats.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getAllUserChats.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.error = '';
      state.fromUsers = payload.reduce(
        (acc: Record<number, number[]>, chat: MonitorChatUser) => {
          if (!acc[chat.user1Id]) {
            acc[chat.user1Id] = [];
          }
          acc[chat.user1Id].push(chat.user2Id);
          return acc;
        },
        {}
      );
      state.toUsers = payload.reduce(
        (acc: Record<number, number[]>, chat: MonitorChatUser) => {
          if (!acc[chat.user2Id]) {
            acc[chat.user2Id] = [];
          }
          acc[chat.user2Id].push(chat.user1Id);
          return acc;
        },
        {}
      );
      state.chatIdMap = payload.reduce(
        (acc: Record<string, string>, chat: MonitorChatUser) => {
          acc[`${chat.user1Id}_${chat.user2Id}`] = chat.id;
          return acc;
        },
        {}
      );
    });
    builder.addCase(getAllUserChats.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getChatMessagesForMonitoring.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getChatMessagesForMonitoring.fulfilled, (state, action) => {
      const payload: any = action.payload.response;
      const chatId: string = action.payload.chatId;
      state.isLoading = false;
      state.error = '';

      // state.messagesByChatId[chatId] = payload;
    });
    builder.addCase(getChatMessagesForMonitoring.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export { getAllUserChats, getChatMessagesForMonitoring };

export const monitorChatsReducer = monitorChatsSlice.reducer;
