import { MRT_ColumnDef, MRT_RowData } from 'material-react-table';

import Status from '../status';

export const getStatusColumn = <T extends MRT_RowData>(
  validationErrors: any,
  accessorKey: string = 'status',
  header: string = 'Status'
): MRT_ColumnDef<T> => {
  return {
    accessorKey: accessorKey,
    header: header,
    Cell: ({ row }) => {
      // Ensure that 'status' exists on T. You might need to adjust based on your data structure.
      return (
        <Status status={row.original[accessorKey] as unknown as boolean} />
      );
    },
    editVariant: 'select',
    editSelectOptions: [
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ],
    muiEditTextFieldProps: {
      select: true,
      error: !!validationErrors?.[accessorKey],
      helperText: validationErrors?.[accessorKey],
      defaultValue: false,
    },
    filterVariant: 'select',
    filterSelectOptions: [
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ],
    filterFn: (row, id, filterValue) => {
      // Similar type assertion here
      return row.original[accessorKey] === filterValue;
    },
  };
};

//TODO Check this later
