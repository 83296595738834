import { RootState } from '../';
import { Event } from '../../models/event';
import { Events } from '../slices/events.slice';

export const eventsStateSelector = (state: RootState): Events => state.events;

export const eventsSelector = (state: RootState): Array<Event> =>
  eventsStateSelector(state).list;

export const eventsIsLoadingSelector = (state: RootState): boolean =>
  eventsStateSelector(state).isLoading;

export const eventsErrorSelector = (state: RootState): string =>
  eventsStateSelector(state).error;

export const activeEventSelector = (state: RootState): Event | undefined =>
  eventsStateSelector(state).activeEvent;
export const activeEventsSelector = (state: RootState): Array<Event> =>
  eventsSelector(state).filter((event) => event.status === true);
