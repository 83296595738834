/* eslint-disable react/jsx-pascal-case */
import {
  createRow,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from 'material-react-table';

import Add from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface ITableProps {
  columns: MRT_ColumnDef<any>[];
  data: any[];
  title: string;
  enableEditing?: boolean;
  isLoading?: boolean;
  tableActions?: Array<React.ReactNode>;
  columnVisibility?: Record<string, boolean>;
  enableGrouping?: boolean;
  defaultValues?: any;

  enableCreate?: boolean;
  onCreateRowSave?: (row: any) => Promise<void>;
  onEditRowSave?: (row: any) => Promise<void>;
  rowActions?: (row: any) => React.ReactNode;
  additionalEditComponents?: (row: any) => React.ReactNode;
  renderDetailPanel?: (row: any) => React.ReactNode;
  resetOnFormClose?: () => void;
  allowEditAction?: ({ row }: { row: any }) => boolean;
}
const Table: React.FC<ITableProps> = ({
  columns,
  data,
  title,
  enableEditing = false,
  columnVisibility = {},
  tableActions = [],
  isLoading = false,
  enableGrouping = false,
  defaultValues = {},
  enableCreate = true,
  onCreateRowSave,
  onEditRowSave,
  rowActions,
  additionalEditComponents,
  renderDetailPanel,
  resetOnFormClose,
  allowEditAction,
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    enableDensityToggle: false,
    enableEditing: enableEditing,
    positionActionsColumn: 'last',
    enableExpanding: true,
    enableGrouping: enableGrouping,
    state: {
      isLoading: isLoading,
    },
    getRowId: (row) => row?.id,
    initialState: {
      density: 'compact',
      columnVisibility: columnVisibility,
    },
    muiEditTextFieldProps: {
      variant: 'outlined',
      fullWidth: true,
    },
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5" className="mb-4 flex self-center">
          Create {title.slice(0, -1)}
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {internalEditComponents}
          {additionalEditComponents && additionalEditComponents(row)}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //Make dense by default
    renderTopToolbarCustomActions: (props) => {
      return (
        <div className="flex items-center">
          <div className="flex items-center">
            <label className="p-4 text-2xl font-bold">{title}</label>
          </div>
        </div>
      );
    },
    renderToolbarInternalActions: ({ table }) => {
      return (
        <Box>
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          {/* <MRT_ShowHideColumnsButton table={table} /> */}
          {/* <MRT_ToggleFullScreenButton table={table} /> */}
          {enableCreate && (
            <IconButton
              aria-label="add"
              onClick={() => {
                table.setCreatingRow(createRow(table, defaultValues));
              }}
            >
              <Add />
            </IconButton>
          )}
          {tableActions}
        </Box>
      );
    },
    renderRowActions: ({ row }) => {
      const showEditAction = allowEditAction ? allowEditAction({ row }) : true;
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          {showEditAction && (
            <Tooltip title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {rowActions && rowActions(row)}
        </Box>
      );
    },
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5" className="mb-4 flex self-center">
          Edit {title.slice(0, -1)}
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {internalEditComponents}
          {additionalEditComponents && additionalEditComponents(row)}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: onCreateRowSave,
    onEditingRowSave: onEditRowSave,
    renderDetailPanel: renderDetailPanel,
    onCreatingRowCancel: () => {
      resetOnFormClose && resetOnFormClose();
    },
    onEditingRowCancel: () => {
      resetOnFormClose && resetOnFormClose();
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default Table;
