import * as React from 'react';

import { routePath } from '../../appRoutes';
import DashboardTile from '../../components/dashboardTile';

interface ISuperAdminDashboardProps {}

const SuperAdminDashboard: React.FunctionComponent<
  ISuperAdminDashboardProps
> = (props) => {
  const tiles = [
    { label: 'Events', path: routePath.events },
    { label: 'Users', path: routePath.users },
    //Committees, Groups, Schools, My Account
    { label: 'Committees', path: routePath.committees },
    { label: 'Reports', path: routePath.reports },
    { label: 'Schools', path: routePath.schools },
    { label: 'Groups', path: routePath.groups },
    { label: 'Settings', path: routePath.myAccount },

    // Add more tiles as needed
  ];
  const delayIncrement = 0.01;
  return (
    <div className="flex flex-wrap justify-center">
      {tiles.map((tile, index) => (
        <DashboardTile
          key={index}
          label={tile.label}
          path={tile.path}
          delay={index * delayIncrement}
        />
      ))}
    </div>
  );
};

export default SuperAdminDashboard;
