import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

type SelectInputProps = FieldHookConfig<string | string[]> & {
  label: string;
  required?: boolean;
  multiple?: boolean;
  options: { value: any; label: string }[];
};

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  required = false,
  options,
  multiple = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <FormControl
      fullWidth
      required={required}
      error={meta.touched && Boolean(meta.error)}
      variant="outlined"
    >
      <InputLabel shrink>{label}</InputLabel>
      <Select
        {...field}
        label={label}
        displayEmpty
        multiple={multiple}
        inputProps={{ 'aria-label': label }}
        notched
        renderValue={(selected) => {
          if (selected === '') {
            return <em>Please select a value</em>;
          }
          if (multiple) {
            return (selected as string[])
              .map((value: any) => {
                const option = options.find((option) => option.value === value);
                return option?.label;
              })
              .join(', ');
          }
          return options.find((option) => option.value === selected)?.label;
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
export default SelectInput;
