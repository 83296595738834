import { FieldHookConfig, useField } from 'formik';
import React from 'react';

import { TextField } from '@mui/material';

type TextInputProps = FieldHookConfig<string> & {
  label: string;
  type?: string;
  required?: boolean;
};

export const TextInput: React.FC<TextInputProps> = ({
  label,
  type = 'text',
  required = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      type={type}
      label={label}
      {...field}
      fullWidth
      required={required}
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
