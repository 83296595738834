import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Event } from '../../models/event';

export const SelectEvent = ({
  events,
  event,
  onChange,
}: {
  events: Event[];
  event: number;
  onChange: (value: number) => void;
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(Number(event.target.value));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="event-select-label">Event</InputLabel>
      <Select
        labelId="event-select-label"
        id="event-select"
        value={`${event}`}
        label="Event"
        onChange={handleChange}
      >
        {events.map((event) => (
          <MenuItem value={event.id}>{event.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectEvent;
