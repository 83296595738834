import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { roles } from '../appRoutes';
import { User } from '../models/user';
import { countriesSelector } from '../store/selectors/app.selectors';
import { committeesListSelector } from '../store/selectors/committees.selectors';
import { groupsListSelector } from '../store/selectors/group.selectors';
import { userByIdSelector } from '../store/selectors/users.selectors';
import { nullToEmpty } from '../utils/utils';

const useChatName = (id: number): string => {
  const [userName, setUserName] = useState<string>('');
  const user = useSelector(userByIdSelector(id));
  const committees = useSelector(committeesListSelector);
  const groups = useSelector(groupsListSelector);
  const countries = useSelector(countriesSelector);

  const groupOptions = useMemo(() => {
    return [
      ...groups
        .filter((group) => group.status)
        .map((group) => ({
          label: group.name,
          value: group.id,
        })),
      ...countries
        .filter((country) => country.status)
        .map((country) => ({
          label: country.name,
          value: country.id,
        })),
    ];
  }, [groups, countries]);
  const computeUserName = (user: User | undefined): string => {
    const role = `${user?.roleId}`;
    const committeeName = `${committees
      ?.filter((committee) => user?.committeeIds.includes(Number(committee.id)))
      .map((committee) => nullToEmpty(committee.name))
      .join('-')}`;

    if (role === roles.OBSERVER) {
      return nullToEmpty(user?.firstName);
    } else if (role === roles.MODERATOR) {
      return `Moderator/${committeeName}`;
    } else if (role === roles.CHAIR) {
      return `Chair/${committeeName}`;
    } else if (role === roles.DEPUTY_CHAIR) {
      return `Deputy Chair/${committeeName}`;
    } else if (role === roles.DELEGATE) {
      return `${
        groupOptions.find((group) => group.value === user?.representeeId)
          ?.label ?? ''
      }/${committeeName}`;
    }
    return 'Admin';
  };
  useEffect(() => {
    setUserName(computeUserName(user));
  }, [user]);
  return userName;
};
export default useChatName;
