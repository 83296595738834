import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Divider from '@mui/material/Divider';

import packageJson from '../../../package.json';
import { routePath } from '../../appRoutes';
import FileViewerById from '../../components/fileViewerById';
import { PrimaryButton } from '../../components/form/primaryButton';
import { TextInput } from '../../components/form/textInput';
import { AppDispatch } from '../../store';
import {
  authErrorSelector,
  authIsLoadingSelector,
  authTokenSelector,
} from '../../store/selectors/auth.selectors';
import { activeEventSelector } from '../../store/selectors/events.selectors';
import { userLogin } from '../../store/slices/auth.slice';

const validationSchema = Yup.object({
  //Trim value and then check for email
  email: Yup.string()
    .trim()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string().required('Required'),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(authIsLoadingSelector);
  const error = useSelector(authErrorSelector);
  const accessToken = useSelector(authTokenSelector);
  const activeEvent = useSelector(activeEventSelector);
  useEffect(() => {
    if (accessToken) {
      setTimeout(() => navigate(routePath.home), 1000);
    }
  }, [accessToken, navigate]);

  return (
    <div className="flex flex-cfol md:flex-row min-h-screen bg-gray-100">
      <div className="hidden lg:flex md:w-1/2 bg-slate-200 items-center justify-center p-10">
        <div className="w-48 h-48 animate-slide-up">
          {activeEvent?.logo && <FileViewerById id={activeEvent?.logo} />}
        </div>
      </div>

      <div className="flex flex-1 items-center justify-center p-10">
        <div className="w-full max-w-md">
          <div className="flex flex-col items-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/diamun-logo.png`}
              alt="DIAMUN Logo"
              className="w-24 h-24 mb-12 animate-slide-up lg:hidden"
            />
            <h3 className="text-center">WELCOME TO</h3>
            <h1 className="text-2xl font-bold text-center text-red-500 mb-10">
              DIAMUN
            </h1>
          </div>

          <Divider />
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(
                userLogin({
                  email: values.email?.trim(),
                  password: values.password,
                })
              );
            }}
          >
            {() => (
              <Form>
                <div className="my-4 mt-8">
                  <TextInput name="email" label="E-Mail Address" />
                </div>
                <div className="my-4">
                  <TextInput name="password" label="Password" type="password" />
                </div>
                <div className="flex items-center justify-end mb-6">
                  <p
                    className="text-sm text-blue-600 hover:underline cursor-pointer"
                    onClick={() => navigate(routePath.forgotPassword)}
                  >
                    Forgot Password?
                  </p>
                </div>
                {error && <p className="text-red-500 text-center">{error}</p>}
                <PrimaryButton
                  label="Login"
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  isLoading={isLoading}
                />
                <div className="text-xs font-bold text-right pt-2">
                  {process.env.NODE_ENV !== 'production'
                    ? `v${packageJson.version}`
                    : ''}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
