import './chat.css';

import debounce from 'lodash.debounce';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { EmojiMartData } from '@emoji-mart/data';
import { Check, DoneAll, Send } from '@mui/icons-material';
import { Alert, Box, Divider, IconButton, TextField } from '@mui/material';
import { TimeIcon } from '@mui/x-date-pickers';

import RenderWithLineBreaks from '../../components/renderWithLineBreaks';
import useChat from '../../hooks/useChat';
import useChatName from '../../hooks/useChatName';
import { User } from '../../models/user';
import { AppDispatch } from '../../store';
import {
  authUserIdSelector,
  authUserProfileSelector,
} from '../../store/selectors/auth.selectors';
import {
  activeChatUsersSelector,
  chatMessagesForChatId,
  chatTotalMessagsSelector,
  isTypingSelector,
} from '../../store/selectors/chat.selectors';
import {
  ChatMessage,
  getChatMessages,
  messageStatus,
} from '../../store/slices/chat.slice';
import { getUsers } from '../../store/slices/users.slice';
import { PER_PAGE } from '../monitorChats/monitorChatMessages';
import { ChatContext, ChatProvider } from './chatContext';
import ChatInput from './chatInput';
import ChatUsers from './chatUsers';

const ChatScreen = () => {
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [searchUserText, setSearchUserText] = useState<string>('');
  const [selectedChatId, setSelectedChatId] = useState<string>('');

  const chatUsers = useSelector(activeChatUsersSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { sendMessage, markMessageAsRead, sendTypingEvent } = useChat();
  React.useEffect(() => {
    dispatch(getUsers({ queryParams: 'accessingFromChats=true' }));
  }, []);

  const handleSearchUserTextChange = (text: string) => {
    setSearchUserText(text);
  };

  const handleSelectChatId = (chatId: string) => {
    setSelectedChatId(chatId);
  };
  console.log({ selectedChatId, selectedUser, searchUserText });

  return (
    <ChatProvider
      value={{
        searchUserText: searchUserText,
        handleSearchUser: handleSearchUserTextChange,
        selectedUser: selectedUser,
        handleSelectUser: setSelectedUser,
        selectedChatId,
        handleSelectChatId: handleSelectChatId,
        sendMessage: sendMessage,
        markMessageAsRead: markMessageAsRead,
      }}
    >
      <div
        className="flex mt-4 border-2 bg-white border-gray-200 rounded-lg "
        style={{ height: 'calc(100vh - 120px)' }}
      >
        <ChatUsers chatUsers={chatUsers} />
        <Divider orientation="vertical" flexItem />
        <div className="w-3/4">
          {!selectedChatId ? (
            <div className="flex flex-row items-center justify-center w-full h-full">
              Select a chat to start messaging
            </div>
          ) : (
            selectedUser && (
              <ChatMessages
                selectedUser={selectedUser}
                chatId={selectedChatId}
                sendTypingEvent={sendTypingEvent}
              />
            )
          )}
        </div>
      </div>
    </ChatProvider>
  );
};

interface IChatMessagesProps {
  selectedUser: User;
  chatId: string;
  sendTypingEvent: (toUserId: number, chatId: string) => void;
}
const ChatMessages: React.FC<IChatMessagesProps> = ({
  selectedUser,
  chatId,
  sendTypingEvent,
}) => {
  const isTyping = useSelector(isTypingSelector(chatId));
  const loggedInUserId = useSelector(authUserIdSelector);
  const messages: ChatMessage[] = useSelector(chatMessagesForChatId(chatId));
  const totalMessages = useSelector(chatTotalMessagsSelector(chatId ?? ''));
  const [limit, setLimit] = React.useState(PER_PAGE);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(
      getChatMessages({
        chatId,
        userId: loggedInUserId,
        limit: limit,
        offset: 0,
      })
    );
  }, [chatId, limit]);
  const { sendMessage } = useContext(ChatContext);
  const handleSendMessage = (message: string) => {
    sendMessage(message, selectedUser.id, chatId);
  };
  const chatName = useChatName(selectedUser.id);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  const handleTyping = () => {
    sendTypingEvent(selectedUser.id, chatId);
  };
  console.log({ chatId, messages, totalMessages, limit });
  React.useEffect(() => {
    setLimit(PER_PAGE);
  }, [chatId]);
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between p-4 text-2xl">
        {chatName ?? 'Select a user to chat'}
      </div>
      <Divider />
      <div className="flex-1 h-full overflow-y-auto p-4 flex flex-col-reverse">
        <div
          id="scrollableDiv"
          style={{
            height: 'auto',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          {/*Put the scroll bar always on the bottom*/}
          <InfiniteScroll
            dataLength={messages?.length ?? 0}
            next={() => {
              console.log('next');
              setLimit(limit + PER_PAGE);
            }}
            style={{
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }} //To put endMessage and loader to the top.
            inverse={true} //
            hasMore={limit < totalMessages}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {messages?.map((msg) => (
              <ChatMessageContainer
                message={msg}
                key={msg.id}
                loggedInUserId={loggedInUserId}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>

      <div className="flex flex-col items-center pt-4">
        {isTyping && (
          <span className="text-xm self-start pl-4 italic">typing...</span>
        )}
        <MessageInput
          key={chatId}
          onSendMessage={handleSendMessage}
          onTyping={handleTyping}
        />
      </div>
    </div>
  );
};

interface IChatMessageContainerProps {
  message: ChatMessage;
  loggedInUserId: number;
  showDeliveryStatus?: boolean;
}

export const ChatMessageContainer: React.FC<IChatMessageContainerProps> = ({
  message,
  loggedInUserId,
  showDeliveryStatus = true,
}) => {
  const isSelf = (message: ChatMessage) => {
    return message.fromUserId === loggedInUserId;
  };
  const { markMessageAsRead, selectedChatId } = useContext(ChatContext);
  const formatTimestamp = (date: Date) => {
    // if date is less than today then add date also with the time
    if (moment(date).isBefore(moment(), 'day')) {
      return `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()} ${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    }

    return `${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };
  const isMe = isSelf(message);
  useEffect(() => {
    if (!isMe && message.status !== messageStatus.READ) {
      // Assuming 0 = pending\
      console.log('messageasread', message.message);
      markMessageAsRead(message.id, selectedChatId);
    }
  }, [message, isMe]);
  return (
    <Box
      sx={{
        textAlign: isMe ? 'right' : 'left',
        mb: 2,
      }}
    >
      <Box
        component="span"
        sx={{
          p: 1,
          bgcolor: isMe ? 'lightgreen' : 'lightblue',
          borderRadius: '10px',
          display: 'inline-block',
        }}
      >
        {<RenderWithLineBreaks text={message.message} />}
        <div>
          <span
            className={`text-xs text-gray-400 flex flex-row items-center ${
              isMe ? 'justify-between' : 'justify-normal'
            }`}
          >
            <span className="mr-2">
              {formatTimestamp(moment(message.createdAt).toDate())}
            </span>
            {showDeliveryStatus && isMe && (
              <ReadStatus status={message.status} />
            )}
          </span>
        </div>
      </Box>
    </Box>
  );
};

interface IMessageInputProps {
  onSendMessage: (message: string) => void;
  onTyping: () => void;
}
const MessageInput: React.FC<IMessageInputProps> = ({
  onSendMessage,
  onTyping,
}) => {
  const userProfile = useSelector(authUserProfileSelector);
  const [message, setMessage] = useState('');
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(evt.target.value);
  };
  const handleSendMessage = () => {
    if (message && message.trim().length > 0) {
      onSendMessage(message);
      setMessage(() => '');
    }
  };
  const handleEmojiSelect = (emoji: EmojiMartData) => {
    if ('native' in emoji) {
      setMessage((currentMessage) => currentMessage + emoji.native);
    }
  };
  // Ensure debounced function is stable across re-renders
  const debouncedOnTyping = useCallback(debounce(onTyping, 200), []);

  useEffect(() => {
    if (message.length > 0) {
      debouncedOnTyping();
    }
    return () => {
      debouncedOnTyping.cancel();
    };
  }, [message.length, debouncedOnTyping]);
  if (!userProfile?.chatAccess) {
    return (
      <div className="w-full">
        <Alert severity="error">
          Your access has been blocked, kindly check with administration.
        </Alert>
      </div>
    );
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (message && message?.trim().length > 0) {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (event.altKey) {
          setMessage((currentValue) => currentValue + '\n');
        } else {
          handleSendMessage();
        }
      }
    }
  };
  return (
    <div className="flex flex-row w-full">
      <TextField
        multiline
        maxRows={3}
        label="Type a message"
        fullWidth
        size="small"
        variant="outlined"
        sx={{ mt: 2, zIndex: 0 }}
        value={message}
        onChange={handleChange}
        InputProps={{
          startAdornment: <ChatInput onEmojiSelect={handleEmojiSelect} />,
          endAdornment: (
            <IconButton onClick={handleSendMessage} disabled={!message}>
              <Send color={!message ? 'disabled' : 'primary'} />
            </IconButton>
          ),
        }}
        onKeyDown={handleKeyDown}
        inputProps={{ style: { padding: '16px' } }}
      />
    </div>
  );
};
interface ReadStatusProps {
  status: number; // 0 for pending, 1 for delivered, 2 for read
}

const ReadStatus: React.FC<ReadStatusProps> = ({ status }) => {
  return (
    <div>
      {status === messageStatus.PENDING && <TimeIcon />}
      {status === messageStatus.SENT && <Check />}
      {status === messageStatus.DELIVERED && <DoneAll />}
      {status === messageStatus.READ && (
        <span style={{ color: 'blue' }}>
          <DoneAll />
        </span>
      )}
    </div>
  );
};

export default ChatScreen;
