import '/node_modules/flag-icons/css/flag-icons.min.css';

import * as React from 'react';
import { useSelector } from 'react-redux';

import { countryToISOCode2MapSelector } from '../store/selectors/app.selectors';

interface ICountryFlagProps {
  country: string;
}

const CountryFlag: React.FunctionComponent<ICountryFlagProps> = ({
  country,
}) => {
  const countryCodes = useSelector(countryToISOCode2MapSelector);
  const code = countryCodes[country];
  return (
    <span className={`fi fi-${code?.toLowerCase() ?? ''} mr-1 text-2xl`}></span>
  );
};

export default CountryFlag;
