import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IDashboardTileProps {
  label: string;
  path: string;
  delay: number;
}
const DashboardTile: React.FC<IDashboardTileProps> = ({
  label,
  path,
  delay,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  // Inline style for animation delay
  const style = {
    animationDelay: `${delay}s`,
    width: '200px',
    height: '200px',
    //random color
    // backgroundColor: `hsl(${Math.random() * 360}, 100%, 80%)`,
  };

  return (
    <div
      className="flex flex-col items-center justify-center cursor-pointer text-2xl mt-12 p-4 m-2 text-center bg-gray-200 rounded-lg transform hover:translate-y-1 transition duration-300 ease-in-out animate-slide-up"
      style={style}
      onClick={handleClick}
    >
      <p>{label}</p>
    </div>
  );
};

export default DashboardTile;
