import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Chip } from '@mui/material';

import ConfirmAction from '../components/confirmAction';
import Table from '../components/table';
import { getStatusColumn } from '../components/table/statusColumn';
import useEventsOptions from '../hooks/useEventsOptions';
import { Committee } from '../models/committee';
import { AppDispatch } from '../store';
import {
  committeesErrorSelector,
  committeesListSelector,
  committeesLoadingSelector,
} from '../store/selectors/committees.selectors';
import {
  createCommittee,
  deleteCommittee,
  getCommittees,
  resetCommitteeError,
  updateCommittee,
} from '../store/slices/committees.slice';
import { resetErrorOnFocus } from '../utils/utils';
import { validateDescription, validateName } from '../utils/validations';

interface ICommitteesProps {}

const Committees: React.FunctionComponent<ICommitteesProps> = (props) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const error: string = useSelector(committeesErrorSelector);
  const isLoading: boolean = useSelector(committeesLoadingSelector);
  const dispatch = useDispatch<AppDispatch>();
  const committes: Committee[] = useSelector(committeesListSelector);
  const eventsOptions = useEventsOptions();
  useEffect(() => {
    dispatch(getCommittees({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = React.useMemo<MRT_ColumnDef<Committee>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 60,
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        Edit: ({ row, table }) => {
          return <div className="pt-2"></div>;
        },
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
        required: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'name',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'title',
        header: 'Title',
        size: 150,
        muiEditTextFieldProps: {
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
        },
      },
      {
        accessorKey: 'description',
        header: 'Descrption',
        size: 150,
        muiEditTextFieldProps: {
          multiline: true,
          rows: 4,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            resetErrorOnFocus({
              key: 'description',
              validationErrors,
              setValidationErrors,
            }),
        },
      },
      {
        accessorKey: 'eventId',
        header: 'Event',

        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.eventId,
          helperText: validationErrors?.eventId,
        },
        Cell: ({ row }: { row: MRT_Row<Committee> }) => {
          return (
            <Chip
              key={row.id}
              label={
                eventsOptions.find(
                  (eventOption) => eventOption.value === row.original.eventId
                )?.label
              }
            />
          );
        },
        filterVariant: 'select',
        filterSelectOptions: eventsOptions,
        editSelectOptions: eventsOptions,
        filterFn: (row, id, filterValue) => {
          console.log(row.original.eventId, filterValue);
          return `${row.original.eventId}` === `${filterValue}`;
        },
      },
      {
        accessorKey: 'userCount',
        header: 'No of Members',
        enableColumnFilter: false,
        Cell: ({ row }) => <div>{row.original.userCount || 0}</div>,
        Edit: ({ row, table }) => {
          return null;
        },
      },
      getStatusColumn<Committee>(validationErrors),
    ],
    [validationErrors]
  );
  return (
    <div className="m-4">
      <Table
        isLoading={isLoading}
        columns={columns}
        columnVisibility={{
          id: false,
          title: false,
          description: false,
        }}
        data={committes}
        title="Committees"
        enableEditing={true}
        additionalEditComponents={(row) =>
          error && <Alert severity="error">{error}</Alert>
        }
        resetOnFormClose={() => {
          setValidationErrors({});
          dispatch(resetCommitteeError());
        }}
        rowActions={(row) => <RowActions row={row} />}
        onCreateRowSave={async ({ values, table }) => {
          values = { ...values, status: !!values.status };
          const newValidationErrors = validateCommittee(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          dispatch(
            createCommittee({
              committee: values,
              onSuccess: () => table.setCreatingRow(null),
            })
          );
          //exit creating mode
        }}
        onEditRowSave={async ({ values, table }) => {
          values = { ...values };
          const newValidationErrors = validateCommittee(values);
          if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
          }
          setValidationErrors({});
          delete values.userCount;
          delete values.eventId;
          dispatch(
            updateCommittee({
              committee: values,
              onSuccess: () => table.setEditingRow(null),
            })
          );
          //exit editing mode
        }}
      />
    </div>
  );
};

interface IRowActions {
  row: MRT_Row<Committee>;
}

const RowActions: React.FC<IRowActions> = ({ row }) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Fragment>
      {/* Delete */}
      <ConfirmAction
        onConfirm={() => dispatch(deleteCommittee({ committee: row.original }))}
      />
    </Fragment>
  );
};

function validateCommittee(committee: Committee) {
  return {
    name: validateName({ value: committee.name, label: 'Committee Name' }),
    // title: !Validations.validateRequired(committee.title)
    //   ? 'Title is Required'
    //   : '',
    description:
      committee.description &&
      validateDescription({
        value: committee.description,
        maxLength: 300,
        label: 'Description',
      }),
  };
}

export default Committees;
