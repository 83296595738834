import * as React from 'react';

import useFile from '../hooks/useFile';
import { FileModel } from '../models/file';
import FileViewer from './fileViewer';

interface IFileViewerByIdProps {
  id: any;
}

const FileViewerById: React.FunctionComponent<IFileViewerByIdProps> = ({
  id,
}) => {
  const file: FileModel = useFile({ id });
  if (id === null) {
    return <p>No file uploaded</p>;
  }
  console.log({ id });
  return <FileViewer fileName={file?.name ?? ''} />;
};

export default FileViewerById;
