import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Group } from '../../models/group';
import API, { ApiResponse } from '../apiUtils';
import { setToastMessage } from './app.slice';

export type GroupsState = {
  list: Group[];
  isLoading: boolean;
  error: string;
};

const initialState: GroupsState = {
  list: [],
  isLoading: false,
  error: '',
};

const getGroups = createAsyncThunk(
  'groups/list',
  async (payload: any = {}, thunkApi) => {
    try {
      const response = await API.get<ApiResponse<Group[]>>(
        '/groups?limit=0&offset=0'
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const createGroup = createAsyncThunk(
  'groups/create',
  async (
    { group, onSuccess }: { group: Group; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      const response = await API.post<ApiResponse<Group>, Group>(
        '/groups',
        group
      );
      thunkApi.dispatch(setToastMessage('Group created successfully'));
      onSuccess && onSuccess();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const updateGroup = createAsyncThunk(
  'groups/update',
  async (
    { group, onSuccess }: { group: Group; onSuccess: () => void },
    thunkApi
  ) => {
    try {
      await API.patch<ApiResponse<Group>, Group>(`/groups/${group.id}`, group);
      thunkApi.dispatch(setToastMessage('Group updated successfully'));
      onSuccess && onSuccess();
      return { ...group };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    resetGroups: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = '';
    },
    resetGroupError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      const payload: any = action.payload;
      console.log({ payload });
      state.isLoading = false;
      state.list = payload;
      state.error = '';
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(createGroup.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      const payload: any = action.payload;
      state.isLoading = false;
      state.list.push(payload);
      state.error = '';
    });
    builder.addCase(createGroup.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
    builder.addCase(updateGroup.pending, (state, action) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = state.list.map((group) =>
        group.id === action.payload.id ? action.payload : group
      );
      state.error = '';
    });
    builder.addCase(updateGroup.rejected, (state, action) => {
      state.isLoading = false;
      state.error = (action.payload as string) || '';
    });
  },
});

const { resetGroups, resetGroupError } = groupsSlice.actions;
export { getGroups, createGroup, updateGroup, resetGroups, resetGroupError };

export const groupsReducer = groupsSlice.reducer;
