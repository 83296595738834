import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';

import { PrimaryButton } from './form/primaryButton';

interface ConfirmActionProps {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
  tooltip?: string;
  iconColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  onConfirm: () => void;
}

const ConfirmAction: React.FC<ConfirmActionProps> = ({
  title = 'Are you sure?',
  description = 'Please verify before confirming, as changes cannot be reverted.',
  tooltip = 'Delete',
  iconColor = 'error',
  icon = <DeleteIcon />,
  onConfirm,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton onClick={handleOpen} color={iconColor}>
          {icon}
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <p>{description}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <PrimaryButton
            label="Confirm"
            color={iconColor}
            onClick={handleConfirm}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmAction;
