import * as React from 'react';
import { useSelector } from 'react-redux';

import styled from '@emotion/styled';

import { Committee } from '../../models/committee';
import { School } from '../../models/school';
import { User } from '../../models/user';
import { representeeByIdSelector } from '../../store/selectors/app.selectors';
import { authUserProfileSelector } from '../../store/selectors/auth.selectors';
import { committeeByIdSelector } from '../../store/selectors/committees.selectors';
import { schoolByIdSelector } from '../../store/selectors/schools.selectors';
import Resolutions from '../resolutions';

export const NameCard = styled.div`
  min-height: 150px;
  width: 300px;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
`;

interface IDelegateDashboardProps {}

const DelegateDashboard: React.FunctionComponent<IDelegateDashboardProps> = (
  props
) => {
  const userProfile: User | undefined = useSelector(authUserProfileSelector);

  const school: School | undefined = useSelector(
    schoolByIdSelector(userProfile?.schoolId ?? 0)
  );
  const committee: Committee | undefined = useSelector(
    committeeByIdSelector(userProfile?.committeeIds[0] ?? 0)
  );
  const representee: any = useSelector(
    representeeByIdSelector(userProfile?.representeeId ?? 0)
  );
  if (!userProfile) {
    return null;
  }
  return (
    <div>
      <div className="flex flex-row items-center justify-center">
        <NameCard className="flex flex-col items-center p-8 bg-slate-200 m-2 animate-slide-up">
          <p className="text-xl font-bold pb-1">Name</p>
          <p className="text-xl">{`${userProfile.firstName} ${
            userProfile.lastName || ''
          }`}</p>
        </NameCard>
        <NameCard
          className="flex flex-col items-center p-8 bg-slate-200 m-2 animate-slide-up"
          style={{ animationDelay: '0.1s' }}
        >
          <p className="text-xl font-bold pb-1">School</p>
          <p className="text-xl">{`${school?.name || ''}`}</p>
        </NameCard>
        <NameCard
          className="flex flex-col items-center p-8 bg-slate-200 m-2 animate-slide-up"
          style={{ animationDelay: '0.2s' }}
        >
          <p className="text-xl font-bold pb-1">Committee</p>
          <p className="text-xl">{`${
            committee?.name ?? 'No Committee assigned'
          }`}</p>
        </NameCard>
        <NameCard
          className="flex flex-col items-center p-8 bg-slate-200 m-2 animate-slide-up text-center"
          style={{ animationDelay: '0.3s' }}
        >
          <p className="text-xl font-bold pb-1">Country Representing</p>
          <p className="text-xl">{`${
            representee?.name ?? 'Not representing any country'
          }`}</p>
        </NameCard>
      </div>

      <div>
        {userProfile.committeeIds?.[0] ? (
          <Resolutions committeeId={userProfile.committeeIds?.[0]} />
        ) : (
          <div className="flex flex-col items-center justify-center">
            You are not part of a committee
          </div>
        )}
      </div>
    </div>
  );
};

export default DelegateDashboard;
